import React from 'react'
import '../form-elem.css'
import { OptionValue } from '../../../interfaces/common';
import { Form } from 'react-bootstrap'

interface RadioInputProps {
  onChange: (...args: any) => void;
  onBlur: () => void;
  options: OptionValue[];
  value: string | undefined;
  name: string;
  inputRef: any;
  placeholder?: string;
  error?: any;
  id?: string;
  disabled?: boolean;
}


function RadioInput({
  onChange,
  onBlur,
  value,
  name,
  inputRef,
  placeholder,
  error,
  id,
  options,
  disabled,
}: RadioInputProps) {
  return (
    <>
      <>
      {
        options.map((opt, index) => {
          // return <div className={index==1 ? "form-check mb-12 ms-3" : "form-check mb-12"}>
          return <div className={"form-check mb-12 ms-3"}>
            <input
              type="radio"
              checked={value == opt.value}
              name={name}
              value={opt.value}
              onChange={(evt) => {
                onChange(evt.target.value)
              }}
              className="form-check-input"
              id={'radioBtn'+index}
              disabled={disabled?disabled:false}
            />
            <label key={index} className="form-check-label project-label" htmlFor={'radioBtn'+index}>
            {opt.label}
            </label>
          
          </div>
        })
      }
      </>
      {
        error && error.message ? <>
          <Form.Control.Feedback type="invalid" className="abcd">
            {error.message}
          </Form.Control.Feedback>
        </> : null
      }
    </>
  )
}

export default RadioInput
