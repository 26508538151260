import { takeLatest, all } from 'redux-saga/effects';
import { SAGA_ACTIONS } from '../_config';

import {
  getUserProfile,
  loginUser,
  loginUserWithOtp,
  logoutUser,
  updateUserProfile,
  registerUser,
  verifyAccount,
  socialLoginRegister, verifyWithOtp
} from './userSaga';

import {

} from './commonSaga';

import { askQuestion, clownLanding, questionsList, claimPage1, claimPage2, myClaimsList, claimDetails, supplierList, myIncompleteClaim, deleteClaim, downloadZip, contactUs, claimPage3, getShortCodeDetails, getCompanyNames, removeFile, getCompanyList, uploadMoreDocument, commentAdd, commentList, commentDelete, getMessageList, sendMessage, deleteMessage, updateReadStatus, getDirectorsList } from './eventSaga';

import {
  getCmsContent
} from './cmsSaga';


export function* sagas() {
  yield all([
    // user saga
    takeLatest(SAGA_ACTIONS.USER.DETAILS, getUserProfile),
    takeLatest(SAGA_ACTIONS.USER.LOGIN, loginUser),
    takeLatest(SAGA_ACTIONS.USER.LOGIN_WITH_OTP, loginUserWithOtp),
    takeLatest(SAGA_ACTIONS.USER.UPDATE_PROFILE, updateUserProfile),

    takeLatest(SAGA_ACTIONS.USER.LOGOUT, logoutUser),
    takeLatest(SAGA_ACTIONS.USER.REGISTRATION, registerUser),
    takeLatest(SAGA_ACTIONS.USER.VERIFY, verifyAccount),
    takeLatest(SAGA_ACTIONS.USER.SOCIAL_LOGIN_REGISTER, socialLoginRegister),
    takeLatest(SAGA_ACTIONS.USER.VERIFY_WITH_OTP, verifyWithOtp),

    //Event Saga
    // takeLatest(SAGA_ACTIONS.EVENT.GET_GAME_SETTINGS, getGameSettings),
    // takeLatest(SAGA_ACTIONS.EVENT.SAVE_COLOR, saveColor),
    // takeLatest(SAGA_ACTIONS.EVENT.GET_EVENT_LIST, getEventList),
    // takeLatest(SAGA_ACTIONS.EVENT.GET_EVENT_GAME, getEventGameList),
    // takeLatest(SAGA_ACTIONS.EVENT.GET_EVENT_GAME_DETAILS, getEventGameDetails),
    // takeLatest(SAGA_ACTIONS.EVENT.PICK_RIDER, pickRider),
    // takeLatest(SAGA_ACTIONS.EVENT.LEADERBOARD, viewLeaderBoard),
    // takeLatest(SAGA_ACTIONS.EVENT.GET_UPCOMING_EVENT, getUpcomingEventList),
    // takeLatest(SAGA_ACTIONS.EVENT.JOIN_EVENT_GAME, joinEventGame),
    // takeLatest(SAGA_ACTIONS.TV_IMAGE.DETAILS, tvImageDetails),
    // takeLatest(SAGA_ACTIONS.EVENT.SAVE_VOTE,saveVote),
    takeLatest(SAGA_ACTIONS.EVENT.ASK_QUESTION,askQuestion),
    takeLatest(SAGA_ACTIONS.EVENT.CLAIM_PAGE1,claimPage1),
    takeLatest(SAGA_ACTIONS.EVENT.CLAIM_PAGE2,claimPage2),
    takeLatest(SAGA_ACTIONS.EVENT.CLAIM_PAGE3,claimPage3),
    takeLatest(SAGA_ACTIONS.EVENT.CLOWN_LANDING,clownLanding),
    takeLatest(SAGA_ACTIONS.EVENT.MY_QUESTIONS, questionsList),
    takeLatest(SAGA_ACTIONS.EVENT.MY_CLAIMS, myClaimsList),
    takeLatest(SAGA_ACTIONS.EVENT.CLAIM_DETAILS, claimDetails),
    takeLatest(SAGA_ACTIONS.EVENT.SUPPLIER_LIST, supplierList),
    takeLatest(SAGA_ACTIONS.EVENT.INCOMPLETE_CLAIM, myIncompleteClaim),
    takeLatest(SAGA_ACTIONS.EVENT.CLAIM_DELETE, deleteClaim),
    takeLatest(SAGA_ACTIONS.EVENT.CLAIM_DOCUMENTS_ZIP, downloadZip),
    takeLatest(SAGA_ACTIONS.EVENT.CONTACT_US, contactUs),
    takeLatest(SAGA_ACTIONS.EVENT.SHORT_CODE_DETAILS, getShortCodeDetails), 
    takeLatest(SAGA_ACTIONS.EVENT.GET_COMPANY, getCompanyNames),
    takeLatest(SAGA_ACTIONS.EVENT.COMPANY_LIST, getCompanyList),
    takeLatest(SAGA_ACTIONS.EVENT.FILE_REMOVE, removeFile),
    takeLatest(SAGA_ACTIONS.EVENT.UPLOAD_MORE_DOCUMENT, uploadMoreDocument),
    takeLatest(SAGA_ACTIONS.EVENT.COMMENT_ADD, commentAdd),
    takeLatest(SAGA_ACTIONS.EVENT.COMMENT_LIST, commentList),
    takeLatest(SAGA_ACTIONS.EVENT.COMMENT_REMOVE, commentDelete),
    takeLatest(SAGA_ACTIONS.EVENT.MESSAGE_LIST, getMessageList),
    takeLatest(SAGA_ACTIONS.EVENT.SEND_MESSAGE, sendMessage),
    takeLatest(SAGA_ACTIONS.EVENT.DELETE_MESSAGE, deleteMessage),
    takeLatest(SAGA_ACTIONS.EVENT.UPDATE_READ_STATUS, updateReadStatus),
    takeLatest(SAGA_ACTIONS.EVENT.DIRECTORS_LIST, getDirectorsList),

    //CMS Saga
    takeLatest(SAGA_ACTIONS.CMS.GET_CMS, getCmsContent),




  ]);
}