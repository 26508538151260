import React, { useEffect } from 'react';
import Sidebar from 'src/components/common/Sidebar';
import Footer from 'src/components/common/Footer';
import RodeoGamePage from 'src/components/events/rodeoGame/rodeoGamePage';
import { useAppUserDetailsSelector } from 'src/_common/hooks/selectors/userSelector';
import { RODEO_PAGE_NAME, STORAGE } from 'src/_config';
import { APPLICATION_NAME } from 'src/_config';
import { Link } from 'react-router-dom';
import { URLS } from 'src/_config';
import OurTeamPage from 'src/components/cms/ourTeam/ourTeamPage';

export default function OurTeamContainer() {

    const userDetails = useAppUserDetailsSelector()
    const userDetail = localStorage.getItem(STORAGE);

    useEffect(() => {
        const prevTitle = document.title
        document.title = `${APPLICATION_NAME} : Our Team`
        return () => {
            document.title = prevTitle
        }
    }, [])

    useEffect(() => {
        
    })

    return (
        <React.Fragment>
            <Sidebar page={RODEO_PAGE_NAME.EVENT_LIST} />
            <section className="main-container">
                <OurTeamPage />
            </section>
            <Footer/>
        </React.Fragment>
    )
}
