import React, { useEffect } from 'react';
import Sidebar from 'src/components/common/Sidebar';
import Footer from 'src/components/common/Footer';
//import RodeoGamePage from 'src/components/events/rodeoGame/rodeoGamePage';
import { useAppUserDetailsSelector } from 'src/_common/hooks/selectors/userSelector';
import { RODEO_PAGE_NAME, STORAGE } from 'src/_config';
import { APPLICATION_NAME } from 'src/_config';
import { Link, useHistory, useParams } from 'react-router-dom';
import { URLS } from 'src/_config';
import { useAuthStatus } from 'src/_common/hooks/auth/authHook';
import { useAppShortCodeDetailsSelector } from 'src/_common/hooks/selectors/eventSelector';

export default function HomePageContainer() {

    const userDetails = useAppUserDetailsSelector()
    const userDetail = localStorage.getItem(STORAGE);
    const history = useHistory();
    const isAuth = useAuthStatus()
    const shortCodeDetails = useAppShortCodeDetailsSelector();

    useEffect(() => {
        const prevTitle = document.title
        document.title = `${APPLICATION_NAME}`
        if(isAuth) {
            console.log("insideauth")
            if(!shortCodeDetails) {
                console.log("insideauth!shortcode")
                history.push(URLS.EVENT.MY_CLAIMS)
            }
            else {
                history.push('/create-claim-1')
            }
        }
        else 
             history.push(URLS.LOGIN)
        return () => {
            document.title = prevTitle
        }
    }, [])

    return (
        <React.Fragment>
            <Sidebar page={RODEO_PAGE_NAME.EVENT_LIST} />
            {/* <section className="main-container">
                <div className="registration_homepage">
                    <div className="className-action">
                        <div className="container-fluid">
                            <div className="reg-banner">
                                <h1>123 Claim</h1>
                                {
                                    userDetail ?
                                    <Link to={URLS.EVENT.MY_CLAIMS}><button type="button" className="green-btn">REGISTER CLAIM</button></Link> : <Link to={URLS.LOGIN}><button type="button" className="green-btn">REGISTER CLAIM</button></Link>
                                }
                            </div>

                        </div>
                        <div className="container-fluid body-container-fluid">
                            <div className="row">
                                <div className="col-lg-3 col-xs-12">
                                    <div className="register-para gray-line">
                                        <h3>Our Practice Area</h3>
                                        <ul>
                                            <li>Practice area 1</li>
                                            <li>Practice area 2</li>
                                            <li>Practice area 3</li>
                                            <li>Practice area 4</li>
                                            <li>Practice area 5</li>
                                            <li>Practice area 6</li>
                                            <li>Practice area 7</li>
                                            <li>Practice area 8</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-9 col-xs-12">
                                    <div className="register-para">
                                        <h3>Our Practice Area</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet molestie ligula, ac gravida lectus. Etiam ullamcorper finibus lorem, et porta lacus feugiat vel. Pellentesque aliquam aliquam enim. Vestibulum sagittis vel orci at molestie. Quisque eu pharetra tellus. Vivamus in sem ac velit cursus interdum vel hendrerit ante. Proin vitae volutpat metus. Pellentesque ut elementum odio, consectetur pellentesque quam. Maecenas iaculis, massa ut dapibus laoreet, felis leo dapibus enim, at imperdiet tellus odio sit amet quam. Fusce eu enim sed est convallis suscipit. Nulla vulputate mollis dolor vel lacinia. Sed volutpat leo porta eleifend ultricies. Aliquam non metus elementum, pellentesque ligula et, viverra nunc. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Suspendisse auctor libero ac malesuada ullamcorper.</p>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet molestie ligula, ac gravida lectus. Etiam ullamcorper finibus lorem, et porta lacus feugiat vel. Pellentesque aliquam aliquam enim. Vestibulum sagittis vel orci at molestie. Quisque eu pharetra tellus. Vivamus in sem ac velit cursus interdum vel hendrerit ante. Proin vitae volutpat metus. Pellentesque ut elementum odio, consectetur pellentesque quam. Maecenas iaculis, massa ut dapibus laoreet, felis leo dapibus enim, at imperdiet tellus odio sit amet quam. Fusce eu enim sed est convallis suscipit. Nulla vulputate mollis dolor vel lacinia. Sed volutpat leo porta eleifend ultricies. Aliquam non metus elementum, pellentesque ligula et, viverra nunc. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Suspendisse auctor libero ac malesuada ullamcorper.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <Footer/>
        </React.Fragment>
    )
}
