import React, { useEffect } from 'react'
import Sidebar from 'src/components/common/Sidebar'
import Footer from 'src/components/common/Footer'
import CreateClaimStep1 from 'src/components/create-claim/createClaimStep1'

export default function CreateClaimStep1Page() {

  return (
    <React.Fragment>
      <Sidebar page={"create-claim"} />
      <CreateClaimStep1 />
      <Footer/>
    </React.Fragment>
  )
}


