import React from 'react';

const LazyMyClaimsPage= React.lazy(() =>
  import('./myClaim')
);

const MyClaimsPage = (props: Record<string, any>) => (
  <React.Suspense fallback={<h1>Loading...</h1>}>
    <LazyMyClaimsPage {...props} />
  </React.Suspense>
);

export default MyClaimsPage