let apiBaseUrl = ''
let socketUrl = ''

let appStage = process.env.REACT_APP_STAGE


if (appStage == 'dev') {
    apiBaseUrl = 'https://class-adminv1.dreamztesting.com/api/v1/'
    socketUrl = 'https://class-adminv1.dreamztesting.com:4260';
  } else if (appStage == 'stage') {
    apiBaseUrl = ''
  } else if (appStage == 'uat') {
    apiBaseUrl = 'https://class-admin.dreamztesting.com/api/v1/'
    socketUrl = 'https://class-admin.dreamztesting.com:4260';
  } else if (appStage == 'prod') {
    apiBaseUrl = 'https://backend.123claim.co.uk/api/v1/'
    socketUrl = 'https://socket.123claim.co.uk:4260'
  }

export const API_BASE_URL = apiBaseUrl
export const SOCKETURL = socketUrl