import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useEventApi } from 'src/_common/hooks/actions/event/appEventApiHook'
import { useAppUserDetailsSelector } from 'src/_common/hooks/selectors/userSelector'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SOCKET_CHANNEL, SOCKET_URL } from 'src/_config';
import useSocket from 'use-socket.io-client';
import moment from 'moment';

interface ChatWindowProps {
  onClose: () => void;
  setShowNewMessageIcon: Function;
}

interface MessageFormValues {
    message: string;
}

const messageSchema = yup.object().shape({
    message: yup
        .string(),
})

function ChatWindow({onClose, setShowNewMessageIcon}:ChatWindowProps) {

    const { register, control, setValue, getValues, handleSubmit, errors, watch, reset } = useForm<MessageFormValues>({
        resolver: yupResolver(messageSchema),
        defaultValues: {
            message: '',
        },
    })

    const eventApi = useEventApi()
    const [messageData,setMessageData] = useState<any>([])
    const userDetails = useAppUserDetailsSelector()
    const [showFooter, setShowFooter] = useState<boolean>(true)

    const getChatList = () => {
        eventApi.callGetMessageList(
            (message:any,resp:any)=>{
              let x = []
              for(var i = resp.data.length-1;i>=0;i--) {
                if(resp.data[i].message) {
                  x.push(resp.data[i]);
                }
              }
              setMessageData(x)
            },
            (message:any)=>{

            }
        )
    }

    const updateReadStatus = () => {
        let params = {
          is_read: "1"
        }
        eventApi.callUpdateReadStatus(
            params,
            (message:any,resp:any)=>{

            },
            (message:any)=>{

            }
        )
    }

    const scrollToBottom = () => {
      var objDiv = document.getElementById("chat");
      if(objDiv) {
        objDiv.scrollTop = objDiv.scrollHeight
      }
    }

    const onSubmit = (values:any) => {
        if(values && values.message) {
            console.log({values})
            let params = {
                message: values.message
            }
            eventApi.callSendMessage(
                params,
                (message:any,resp:any)=>{
                    console.log({resp})
                    reset()
                    // getChatList()
                },
                (message:any)=>{
                  
                }
            )
        }
    }

    const deleteMessage = (id:any) => {
        eventApi.callDeleteMessage(
            {id},
            (message:any,resp:any)=>{
                console.log({resp})
                let x = messageData?.filter((m:any)=>m.id!=id)
                setMessageData(x)
                console.log({x})
                // getChatList()
            },
            (message:any)=>{

            }
        )
    }

    //Socket Implementation start here
    const [socket] = useSocket(SOCKET_URL, {
        autoConnect: false,
    });
  
    useEffect(() => {
        socket.connect()
        
        socket.on(SOCKET_CHANNEL.MESSAGE_SEND, (data: any) => {
          if(data?.room_details?.user_id==userDetails?.id) {
            console.log({data})
            let x = messageData
            if(!x.find((d:any)=>d.id==data.id)) {
              x.push(data)
              setMessageData(x)
              console.log({x})
            }
            // getChatList()
          }
        });
  
        socket.on(SOCKET_CHANNEL.MESSAGE_DESTROY, (data: any) => {
          if(data?.room_details?.user_id==userDetails?.id) {
            console.log({data})
            // getChatList()
          }
        });

        socket.on(SOCKET_CHANNEL.MESSAGE_READ, (data: any) => {
          if(data?.room_details?.user_id==userDetails?.id) {
            console.log({data})
          }
        });

        socket.on(SOCKET_CHANNEL.MESSAGE_PERMISSION, (data: any) => {
          console.log({data})
          if(data?.user_id==userDetails?.id && data?.status==0) {
            setShowFooter(false)
          }
          if(data?.user_id==userDetails?.id && data?.status==1) {
            setShowFooter(true)
          }
        });
  
        return () => {
            socket.disconnect()
        }
    }, [socket, messageData])

    useEffect(()=>{
        getChatList()
    },[])

    useEffect(()=>{
      if(messageData && messageData.length) {
        scrollToBottom()
        setShowNewMessageIcon(false)
        localStorage.removeItem("newMsg")
        updateReadStatus()
      }
    },[messageData])

    useEffect(()=>{
      if(userDetails?.allow_messaging=="1") {
        setShowFooter(true)
      }
      else {
        setShowFooter(false)
      }
    },[userDetails])

  return (
    <div className="chat-window">
      <div className="mask">
        {/* Main container */}
        <div className="container-sec">
          {/* Conversation view */}
          <section className="conversation">
            {/* Conversation view header */}
            <header className="conversation__header">
              {/* <button class="conversation__btn btn btn--list" title="Conversation list">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
              </button> */}
              <div className="conversation__info">
                {/* <span class="conversation__status"></span> */}
                <span className="conversation__name">
                  Chat
                </span>
              </div>
              <div className="conversation__header-actions">
                {/* <button class="conversation__btn btn btn--camera" title="Start videocall">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polygon points="23 7 16 12 23 17 23 7"></polygon><rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect></svg>
              </button> */}
                <button
                    className="close" 
                    onClick={onClose}
                    title="Close Window"
                >
                  <img src="/images/close-btn-icon.png" alt=""/>
                </button>
              </div>
            </header>
            <input type="hidden" id="message_page_no" defaultValue={1} />
            {/* Conversation view messages */}
            <div className="conversation__body message-section" id="chat">
              <div className="mt-4">
                {messageData && messageData.map((item:any,i:any)=>
                    item.message ? <div key={i} className={"conversation__bubble conversation__bubble--"+(userDetails?.id==item.sender_id ? "right" : "left")+" delete_event"}>
                        <div className={"chat-txt-"+(userDetails?.id==item.sender_id ? "right" : "left")}>
                            <p className="conversation__text">{item.message}{userDetails?.id==item.sender_id && <i className="fa fa-times-circle delete_event" onClick={()=>deleteMessage(item.id)}></i>}</p>                            
                            <span>{!(userDetails?.id==item.sender_id) && item?.sender_details?.full_name} {moment(item.created_at).format('DD-MM-yy hh:mm a')}
                            {/* {(userDetails?.id==item.sender_id) && <i className="fa fa-check read"></i>} */}
                            </span>
                        </div>
                    </div> : null
                )}
              </div>
            </div>
            {/* Conversation view footer */}
            {showFooter &&
              <footer className="conversation__footer">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <input
                    type="text"
                    name="message"
                    className="conversation__write"
                    ref={register}
                    autoComplete="off"
                  />
                  <button
                    className="conversation__btn btn btn--send"
                    type="submit"
                    title="Send Message"
                  >
                    <i className="fa fa-paper-plane" aria-hidden="true" />
                  </button>
                </form>
              </footer>
            }
          </section>
        </div>
      </div>
    </div>
  )
}

export default ChatWindow