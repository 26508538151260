import React from 'react';

const LazyCreateClaimStep2Page= React.lazy(() =>
  import('./createClaimStep2')
);

const CreateClaimStep2Page = (props: Record<string, any>) => (
  <React.Suspense fallback={<h1>Loading...</h1>}>
    <LazyCreateClaimStep2Page {...props} />
  </React.Suspense>
);

export default CreateClaimStep2Page