import React, { useEffect, useRef, useState } from 'react'
import CreateClaimInfoModal from '../events/modal/createClaimInfoModal copy';

interface HeadingProps {
    step: string;
    headingText: string;
    paraText: string;
    icon: boolean;
}


export default function Heading({ step, headingText, paraText, icon }: HeadingProps) {

    const [show, setShow] = useState<boolean>(false)

    return (
        <>
            <div className="fix-sec">
                <ul className="fix-numbers">
                    {['1', '2', '3'].map((n: any) =>
                        <li className={n == step ? "active" : ''}>{n}</li>
                    )}
                </ul>
                {icon ?
                    <div className='d-flex justify-content-between'>
                        <div>
                            <h1 className="heading-text">{headingText}</h1>
                            <p className="para-text">{paraText}</p>
                        </div>
                        <i className="fa fa-info-circle" style={{ cursor: "pointer" }} onClick={() => setShow(true)}></i>
                    </div>
                    :
                    <div>
                        <h1 className="heading-text">{headingText}</h1>
                        <p className="para-text">{paraText}</p>
                    </div>
                }
            </div>

            <CreateClaimInfoModal
                shouldShow={show}
                setShow={setShow}
                step= {step}
            />
        </>
    )
}
