import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useAppUserDetailsSelector } from 'src/_common/hooks/selectors/userSelector';
import { useAppClaimDetailsSelector, useAppShowAnnaModalSelector } from 'src/_common/hooks/selectors/eventSelector';
import { API_URL, CRYPTO_SECRET_KEY, STORAGE, URLS, VIDEO_URLS } from 'src/_config';
import { useEventApi } from 'src/_common/hooks/actions/event/appEventApiHook';
import { useAppEventAction } from 'src/_common/hooks/actions/event/appEventActionHook';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useToaster } from 'src/_common/hooks/actions/common/appToasterHook';
import { OptionValue } from 'src/_common/interfaces/common';
import moment from 'moment';
import Heading from './Heading';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useAppFileProgressAction } from 'src/_common/hooks/actions/fileProgress/appFileProgressAction';
import AskAnnaModal from '../common/AskAnnaModal';

function revisedRandId() {
    return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
}


interface CommentsArrayValues {
    comment: string,
}
interface CreateClaimStep3FormValues {
    company_name: string;
    reg_address: string;
    reg_no: string;
    phone_no: string;
    email: string;
    claim_for: string;
    monthly_spend: string;
    supplier_used: OptionValue | undefined;
    broker_used: string;
    contract_start_date: Date | undefined;
    contract_end_date: Date | undefined;
    management_fee: string;
    amount: string;
    comments: CommentsArrayValues[];
}


const CreateClaimStep3Schema = yup.object().shape({
    file: yup
        .array()
        .required('Please upload documents'),
    company_name: yup
        .string()
        .required('Company Name is required'),
    reg_address: yup
        .string()
        .required('Registered Address is required'),
    phone_no: yup
        .string()
        .required('Phone Number is required'),
    email: yup
        .string()
        .email('Please provide a valid email address')
        .required('Email is required'),
    claim_for: yup
        .string()
        .required('Claim Option is required'),
    monthly_spend: yup
        .string()
        .required('Monthly Spend Amount is required'),
    supplier_used: yup
        .object()
        .nullable()
        .shape({
            value: yup.string().required('Supplier is required'),
        })
        .required('Supplier is required'),
    contract_start_date: yup
        .date()
        .required('Contract Start Date is required'),
    contract_end_date: yup
        .date()
        .required('Contract End Date is required'),
    management_fee: yup
        .string()
        .required('Management Fee is required'),
    amount: yup
        .string()
        .required('Amount is required')

})
const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTO_SECRET_KEY)

export default function CreateClaimStep3() {

    const { register } = useForm<CreateClaimStep3FormValues>({
        resolver: yupResolver(CreateClaimStep3Schema),
        defaultValues: {
            company_name: '',
            reg_address: '',
            reg_no: '',
            phone_no: '',
            email: '',
            claim_for: '1',
            monthly_spend: '',
            supplier_used: undefined,
            broker_used: '',
            contract_start_date: '',
            contract_end_date: '',
            management_fee: '2',
            amount: '',
            comments: [
                { comment: '' }
            ],
        },
    })

    const eventApi = useEventApi()
    const toast = useToaster()
    const userDetails = useAppUserDetailsSelector()
    const history = useHistory()
    const claimDetails = useAppClaimDetailsSelector()
    const eventAction = useAppEventAction()
    const fileAction = useAppFileProgressAction()

    const [files, setFiles] = useState<any>([])
    const [fileDetails, setFileDetails] = useState<any>([])
    const [myClaimList, setMyClaimsList] = useState<any>([])
    const [commentsList, setCommentsList] = useState<any>([])
    const [commentValue, setCommentValue] = useState<string>('')
    const [incompleteClaimList, setIncompleteClaimList] = useState<any>()
    const [count, setCount] = useState<number>(0)
    const [respCount, setRespCount] = useState<number>(0)
    const [progressInfo, setProgressInfo] = useState<any>([]);
    const showAskAnnaModal = useAppShowAnnaModalSelector()

    const claim_id = localStorage.getItem("claim_id")

    const wrapperRef: any = useRef(null);
    const onDragEnter = () => wrapperRef.current.classList.add('dragover');
    const onDragLeave = () => wrapperRef.current.classList.remove('dragover');
    const onDrop = () => wrapperRef.current.classList.remove('dragover');

    const onChange = (e: any, status?: any) => {
        let x: any = [...files]
        let newFiles = e.target.files
        console.log({newFiles})
        if (newFiles && newFiles.length) {
            let previousProgressInfos: any[] = progressInfo && progressInfo.length ? progressInfo : []
            let _progressInfos: any = [...previousProgressInfos]
            for (let i = 0; i < newFiles.length; i++) {
                if (status != "edit") {
                    let type = newFiles[i].type
                    let imageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/zip', 'application/zip-compressed', 'application/x-zip-compressed']
                    if (imageTypes.includes(type)) {
                        _progressInfos.push({
                            progressId: revisedRandId(),
                            percentage: 0,
                            fileName: newFiles[i].name,
                            uploaded: false,
                            started: false,
                            file: newFiles[i]
                        });
                        x.push(newFiles[i])
                    }
                    else {
                        toast.error("File of type " + type.substring(type.indexOf('/') + 1) + " is not allowed")
                    }
                }
            }
            setProgressInfo(_progressInfos)
            setFiles(x)
            e.target.value=null
        }
    }

    const getGetIncompleteList = () => {
        var params: any = {
            id: claim_id
        }
        eventApi.callClaimDetails(params, (message: string, resp: any) => {
            if (resp) {
                setIncompleteClaimList(resp.data)
                setFileDetails(resp.data.claim_document)
            }
        }, (message: string) => {
        })
    }

    const removeFile = (id: any) => {
        eventApi.callRemoveFile({ id }, (message: string, resp: any) => {
            toast.success(message)
            getGetIncompleteList()
        }, (message: string, resp: any) => {
        })
    }

    const deleteFile = (index: any) => {
        let file = fileDetails.find((f: any, i: any) => i == index)
        if (file && file.id) {
            removeFile(file.id)
        }
    }

    const getMyClaimsList = () => {
        var params: any = {

        }
        eventApi.callMyClaims(params, (message: string, resp: any) => {
            if (resp) {
                setMyClaimsList(resp.data)
            }
        }, (message: string) => {
        })
    }

    const handleFileUpload = (file?: any, idx?: any, filterId?: any) => {
        let fd: any = new FormData()
        fd.append("from", "2")
        fd.append(`images[${idx}]`, file.file)
        if (claim_id) {
            fd.append("id", String(claim_id))
        }
        let previousFiles = progressInfo && progressInfo.length ? [...progressInfo] : [];
        if (previousFiles && previousFiles.length && previousFiles[idx]) {
            previousFiles[idx]['started'] = true
        }
        setProgressInfo(previousFiles)
        fileUploadProgress(fd, idx, filterId)
    }

    const fileUploadProgress = async (params: any, idx: any, filterId?: any) => {
        try {
            const token = localStorage.getItem(STORAGE);
            let id = params.get("id")
            params.delete("id")

            const resp: any = await axios.post(API_URL.EVENT.UPLOAD_MORE_DOCUMENT + "/" + id, params, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((100 * progressEvent.loaded) / progressEvent.total);
                    if (progressInfo && progressInfo.length) {
                        setProgressInfo((items: any) => {
                            return items.map((item: any, index: number) => {
                                return {
                                    ...item,
                                    percentage: index === idx ? progress : item.percentage,
                                    uploaded: index === idx ? (progress >= 100) : false
                                }
                            })
                        })
                    }

                },
            })

            setRespCount((respCount: number) => {return respCount+1})

            await new Promise((resolve) => {
                setTimeout(() => resolve("success"), 1500);
            });

            if (resp.status == 200) {
                toast.success(resp?.data?.message)
                // getGetIncompleteList()
                setFiles([])
                // setProgressInfo([]);
                // if (progressInfo && progressInfo.length) {
                //     setProgressInfo((items: any) => {
                //         return items.filter((item: any, index: number) => (item.progressId !== filterId))
                //     })
                // }
            }
            else {
                toast.error(resp.message)
            }
        } catch (err: any) {
            setRespCount((respCount: number) => {return respCount+1})
            console.log({ err })
            toast.error(err?.response?.data?.message)
        }
    }

    const onRegistrationFormSubmit = (action?: any) => {
        if ((files && files.length) || (fileDetails && fileDetails.length)) {
            let fd: any = new FormData()
            if (claim_id) {
                fd.append("id", String(claim_id))
            }
            if (action == "close") {
                fd.append("claim_complete", 1)
            }
            else if (action == "another") {
                fd.append("claim_complete", 1)
            }
            else if (action == "new") {
                fd.append("claim_complete", 1)
            }
            else if (action == "later") {
                fd.append("claim_complete", 0)
            }

            eventApi.callCreateClaimStep3(fd, (message: string, resp: any) => {
                toast.success(message)
                if (action == "close") {
                    localStorage.removeItem("is_claim")
                    history.push(URLS.EVENT.MY_CLAIMS)
                    removeAnother()
                }
                else if (action == "another") {
                    if (claim_id) {
                        localStorage.setItem("is_claim", claim_id)
                    }
                    localStorage.setItem("another", "another")
                    history.push('/create-claim-2/' + cryptr.encrypt(claim_id))
                }
                else if (action == "new") {
                    localStorage.removeItem("is_claim")
                    eventAction.resetstep1details()
                    history.push('/create-claim-1')
                }
                else if (action == "later") {
                    history.push('/my-claims')
                }
                localStorage.removeItem("claim_id")
            }, (message: string, resp: any) => {
                toast.error(message)
            })
        }
    }

    const submitAndAddAnother = () => {
        getMyClaimsList()
        onRegistrationFormSubmit("another")
        localStorage.setItem('claimNo', myClaimList.length + 1)
    }

    const submitAndAddNew = () => {
        getMyClaimsList()
        removeAnother()
        onRegistrationFormSubmit("new")
        localStorage.setItem('claimNo', myClaimList.length + 1)
    }

    const handleComments = (values: any, index?: any) => {
        setCommentValue(values)
    }

    const saveComment = () => {
        let params: any = {}
        if (commentValue != '') {
            params["id"] = String(claim_id)
            params["comments"] = String(commentValue)
            eventApi.callCommentAdd(params,
                (message: string, resp: any) => {
                    toast.success(message)
                    setCommentValue('')
                    getCommentsList()
                }, (message: string) => {
                    toast.error(message)
                })
        }

    }

    const getCommentsList = () => {
        let params = {
            id: String(claim_id)
        }
        eventApi.callGetCommentList(params,
            (message: string, resp: any) => {
                // console.log(resp)
                if (resp) {
                    setCommentsList(resp)
                }
            }, (message: string) => {
                toast.error(message)
            })
    }

    const handleCommentsRemove = (index: any) => {
        let params = {
            id: String(claim_id),
            comment_id: String(index)
        }
        eventApi.callCommentDelete(params,
            (message: string, resp: any) => {
                toast.success(message)
                getCommentsList()
            }, (message: string) => {
                toast.error(message)
            })
    }

    const removeAnother = () => {
        localStorage.removeItem("another")
    }

    const openAskAnaModal = () => {
      eventAction.showAnnaModal(true)
    }
  
    const closeAskAnnaModal = () => {
      eventAction.showAnnaModal(false)
    }

    useEffect(() => {
        if (count == 0) {
            if (claimDetails && claimDetails.claim_document && claimDetails.claim_document.length) {
                let parms = {
                    target: {
                        files: claimDetails.claim_document
                    }
                }
                // console.log("params edit")
                onChange(parms, "edit")
                setCount(count + 1)
            }
            // console.log({ count })
        }
    }, [claimDetails])

    useEffect(() => {
        let x = document.getElementById('class_action_body')
        if (x) {
            x.style.backgroundColor = ""
        }
    }, [])

    useEffect(() => {
        getGetIncompleteList()
        getMyClaimsList()
        getCommentsList()
        fileAction.resetFileProgress()
    }, [])

    useEffect(() => {
        if (progressInfo && progressInfo.length) {
            for (let [index, item] of progressInfo.entries()) {
                if (!item.started && !item.uploaded) {
                    handleFileUpload(item, index, item.progressId)
                }
            }
        }
    }, [progressInfo])

    useEffect(() => {
        if(progressInfo.length > 0 && (progressInfo.length === respCount)){
            setTimeout(() =>{
                getGetIncompleteList()
                setProgressInfo([])
                setRespCount(0)
            },1000)
        }
    },[respCount])

    console.log({ progressInfo, respCount })

    return (
        <div className="login-sec claim-sec">
            <div className="row">
                <Heading
                    step="3"
                    headingText="Documents"
                    paraText="Upload documents supporting your claim"
                    icon={true}
                />
                <div className="drag-drop">
                    <>
                        <div
                            ref={wrapperRef}
                            className="drop-file-input"
                            onDragEnter={onDragEnter}
                            onDragLeave={onDragLeave}
                            onDrop={onDrop}
                        >
                            <h4>Drag and drop your<br />
                                files here to attach<br />
                                OR</h4>
                            <input type="file" name="file" ref={register} onChange={onChange} multiple />
                        </div>


                        <div className="drag-btn">

                            <button type="button" className="project-btn ">
                                Browse
                                <input type="file" name="file" ref={register} onChange={onChange} multiple />
                            </button>
                            <button type="button" className="project-btn take-picture">
                                Take Picture
                                <input type="file" name="file" ref={register} capture="environment" onChange={onChange} />
                            </button>
                        </div>
                    </>
                </div>
                <div className="document-table table-responsive">
                    {progressInfo && progressInfo.length > 0 ? progressInfo.map((f: any, i: any) =>
                        <tr key={i}>
                            {f && f.started ?
                                <React.Fragment>
                                    <td className="doc-id-text step2-row1">{f?.fileName}</td>
                                    <td className="doc-norm-text step2-row2">
                                        <div className="progress mt-2">
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{ width: `${f?.percentage}%` }}
                                                aria-valuenow={100}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            />
                                            {f?.percentage}
                                        </div>
                                    </td>
                                </React.Fragment>
                                : null
                            }
                        </tr>
                    )
                        : null}
                    <table>
                        <tbody>
                            {
                                fileDetails && fileDetails.length ?
                                    fileDetails.map((f: any, i: any) =>
                                        <tr key={i}>
                                            <td className="doc-id-text step2-row1">{f?.original_name}</td>
                                            <td className="doc-norm-text step2-row2">{moment(f?.date).format('DD-MM-yy h:mm a')}</td>
                                            <td className="step2-row3"><a onClick={() => deleteFile(i)}className="action-btn"><i className="fa-regular fa-trash-can" /></a></td>
                                        </tr>
                                    )
                                    : <tr><td className="no-files-error">No files selected. Please upload files to submit your claim.</td></tr>
                            }

                        </tbody>
                    </table>
                </div>
                <form>
                    <div className="col-sm-12 inner-doc-cmnt">
                        <h5>Comments<span> (Optional)</span></h5>
                        <div className="row mb-2 inner-comment-fix">
                            <div className="col-md-12">
                                <div className="object-row">
                                    <span>Comment</span>
                                    <textarea cols={20} rows={2} name={`comment`} value={commentValue} ref={register} onChange={(e) => handleComments(e.target.value)} className="form-control" />
                                </div>
                                <div className="add-item-wrap mt-2">
                                    <button type="button" onClick={saveComment} className="project-btn">Add Comment</button>
                                </div>
                            </div>
                        </div>
                        <ol type='1' className='mb-0'>
                            {commentsList && commentsList.length > 0 ?
                                commentsList.map((comment: any, index: any) => (
                                    <div className="row mb-2 inner-comment-fix mt-2">
                                        <div className="col-md-12">
                                            <li key={index}>{userDetails?.full_name} &nbsp;&nbsp; <i>{comment?.created_at_format}</i> <br /><span> {comment?.comment}</span> </li>
                                        </div>
                                        <div className="col-md-12">
                                            <a onClick={() => handleCommentsRemove(comment.id)} className="action-btn del-icon"><i className="fa-regular fa-trash-can" /></a>
                                        </div>
                                    </div>
                                ))
                                :
                                null
                            }
                        </ol>
                    </div>
                    <div className="drag-btn drag-btn-bottom backBtn-1 btn-merg-fix">
                        <div className="row">
                            <div className="col-sm-12">
                                <button type="button" className="project-btn mt-2 resp-left-btn" onClick={() => onRegistrationFormSubmit("later")}>Save & Close</button>
                            </div>
                            <div className="col-sm-12">
                                <button type="button" className="project-btn mt-2 resp-left-btn" onClick={() => onRegistrationFormSubmit("close")}>Submit & Close</button>
                            </div>
                            <div className="col-sm-12">
                                <button type="button" className="project-btn mt-2 resp-left-btn" onClick={submitAndAddAnother}>Submit & Add Additional Claim</button>
                            </div>
                            <div className="col-sm-12">
                                <button type="button" className="project-btn mt-2 resp-left-btn" onClick={submitAndAddNew}>Submit & Add Claim for New Company</button>
                            </div>
                            <div className="col-sm-12 col-lg-12 mt-2 text-center">
                                <Link to={`/create-claim-2/${cryptr.encrypt(claim_id)}`}><i className="fa fa-angle-double-left" aria-hidden="true"></i> Go to previous step</Link>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            {showAskAnnaModal && 
                <AskAnnaModal
                    shouldShow={showAskAnnaModal}
                    onClose={closeAskAnnaModal}
                    url={VIDEO_URLS.STEP_3}
                    title={"Step 3"}
                />
            }
        </div>
    )
}
