import { useDispatch } from 'react-redux'
import { ACTIONS } from '../../../../_config'

export function useAppGlobalAction() {
  const dispatch = useDispatch()

  const showLoginModal = () => {
    dispatch({
      type: ACTIONS.GLOBAL.SET_LOGIN_MODAL,
      payload: true,
    })
  }

  const hideLoginModal = () => {
    dispatch({
      type: ACTIONS.GLOBAL.SET_LOGIN_MODAL,
      payload: false,
    })
  }

  const setDeviceFcmToken = (token: string) => {
    dispatch({
      type: ACTIONS.GLOBAL.SET_FCM_TOKEN,
      payload: token,
    })
  }

  const unsetDeviceFcmToken = () => {
    dispatch({
      type: ACTIONS.GLOBAL.SET_FCM_TOKEN,
      payload: undefined,
    })
  }

  const setDeviceOS = (os: string) => {
    dispatch({
      type: ACTIONS.GLOBAL.SET_DEVICE_OS,
      payload: os,
    })
  }

  const unsetDeviceOS = () => {
    dispatch({
      type: ACTIONS.GLOBAL.SET_DEVICE_OS,
      payload: 'unknown',
    })
  }

  return {
    showLoginModal,
    hideLoginModal,
    setDeviceFcmToken,
    unsetDeviceFcmToken,
    setDeviceOS,
    unsetDeviceOS,
  }
}