import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useAppGuestUserIdSelector, useAppUserDetailsSelector } from 'src/_common/hooks/selectors/userSelector';
import { useAppClaimDetailsSelector, useAppRodeoEventLoginRequiredSelector, useAppStep1DetailsSelector } from 'src/_common/hooks/selectors/eventSelector';
import { CRYPTO_SECRET_KEY, URLS } from 'src/_config';
import { useEventApi } from 'src/_common/hooks/actions/event/appEventApiHook';
import { useAppEventAction } from 'src/_common/hooks/actions/event/appEventActionHook';
import { useAppUserAction } from 'src/_common/hooks/actions/user/appUserActionHook';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useToaster } from 'src/_common/hooks/actions/common/appToasterHook';
import { OptionValue } from 'src/_common/interfaces/common';
import moment from 'moment';
import ReactPlayer from 'react-player'

const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTO_SECRET_KEY)

interface AskAnnaModalProps {
    shouldShow: boolean;
    onClose: Function;
    url: string;
    title: string;
}

export default function AskAnnaModal({ shouldShow, onClose, url, title }: AskAnnaModalProps) {

    const eventApi = useEventApi()
    const toast = useToaster()
    const userDetails = useAppUserDetailsSelector()
    const history = useHistory()
    
    useEffect(()=>{

    },[shouldShow])

    return (
        <React.Fragment>
            <Modal
                show={shouldShow}
                backdrop="static"
                keyboard={false}
                className="sendvoicemail send-video-message theme-custom-modal"
                size='lg'
                centered
                contentClassName='custom-modal'
            >
                <Modal.Header>
                    <h2>Ask Ana - {title}</h2>
                    <button type="button" className="close" onClick={()=>onClose()}>
                        <img src="/images/close-btn-icon.png" alt=""/>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body pl-0 pr-0">
                        <ReactPlayer
                            url={url}
                            width='100%'
                            height='100%'
                            controls={true}
                            playing={true}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment >
    )
}
