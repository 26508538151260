import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, SAGA_ACTIONS, STORAGE } from '../_config';
import { CallApi } from './api/callApi';

export function* registerUser(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.REGISTRATION, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      if(resp.data.data.token) {
        localStorage.setItem(STORAGE, resp.data.data.token);
        console.log(localStorage.getItem(STORAGE))
        yield put({
          type: ACTIONS.USER.LOGIN,
          payload: {
            user: resp.data.data.user,
            token: resp.data.data.token,
          }
        })
      }
      // yield put({
      //   type: ACTIONS.USER.RESET_GUEST_USER_ID,
      // })
      // yield put({
      //   type: ACTIONS.EVENT.RESET_PICK_ID,
      // })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* loginUser(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.LOGIN, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      // localStorage.setItem(STORAGE, resp.data.data.token);
      // yield put({
      //   type: ACTIONS.USER.LOGIN,
      //   payload: {
      //     user: resp.data.data.user,
      //     token: resp.data.data.token,
      //   }
      // })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* loginUserWithOtp(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.LOGIN_WITH_OTP, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      localStorage.setItem(STORAGE, resp.data.data.token);
      yield put({
        type: ACTIONS.USER.LOGIN,
        payload: {
          user: resp.data.data.user,
          token: resp.data.data.token,
        }
      })
      yield put({
        type: ACTIONS.USER.RESET_GUEST_USER_ID,
      })
      yield put({
        type: ACTIONS.EVENT.RESET_PICK_ID,
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* verifyAccount(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.VERIFY, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      if(resp.data.data.token) {
        localStorage.setItem(STORAGE, resp.data.data.token);
        yield put({
          type: ACTIONS.USER.LOGIN,
          payload: {
            user: resp.data.data.user,
            token: resp.data.data.token,
          }
        })
      }
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* logoutUser(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.LOGOUT, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      localStorage.removeItem(STORAGE)
      yield put({
        type: ACTIONS.USER.LOGOUT,
      })
      yield put({
        type: ACTIONS.EVENT.RESET_PICK_ID,
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* updateUserProfile(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.UPDATE_PROFILE, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getUserProfile(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.USER.DETAILS, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {

      yield put({
        type: ACTIONS.USER.ME,
        payload: resp.data.data.user
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* socialLoginRegister(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.SOCIAL_LOGIN_REGISTER, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      localStorage.setItem(STORAGE, resp.data.data.token);
      yield put({
        type: ACTIONS.USER.LOGIN,
        payload: {
          user: resp.data.data.user,
          token: resp.data.data.token,
        }
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* verifyWithOtp(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.VERIFY_WITH_OTP, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}