import { SAGA_ACTIONS } from 'src/_config'
import { LoginReq, LoginWithOtpReq, UpdateUserProfile, RegistationReq, SocialLoginRegisterReq, VerifyWithOtpReq } from 'src/_common/interfaces/ApiReqRes'
import { useApiCall } from '../common/appApiCallHook'

export function useUserApi() {

  const callApi = useApiCall()

  const login = (data: LoginReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGIN, data, onSuccess, onError);
  }

  const loginWithOtp = (data: LoginWithOtpReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGIN_WITH_OTP, data, onSuccess, onError);
  }

  const register = (data: RegistationReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.REGISTRATION, data, onSuccess, onError);
  }

  const verify = (data: LoginWithOtpReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.VERIFY, data, onSuccess, onError);
  }

  const updateUserProfile = (data: UpdateUserProfile, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.UPDATE_PROFILE, data, onSuccess, onError);
  }

  const getMe = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.DETAILS, null, onSuccess, onError);
  }

  const logout = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGOUT, null, onSuccess, onError);
  }

  const socialLoginRegister = (data: SocialLoginRegisterReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.SOCIAL_LOGIN_REGISTER, data, onSuccess, onError);
  }

  const verifyWithOtp = (data: VerifyWithOtpReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.VERIFY_WITH_OTP, data, onSuccess, onError);
  }

  return {
    callLogin: login,
    callLoginWithOtp: loginWithOtp,
    callUpdateUserProfile: updateUserProfile,
    callLogout: logout,
    callGetMe: getMe,
    callRegistration: register,
    callVerifyAccount: verify,
    callSocialLoginRegister: socialLoginRegister,
    callVerifyWithOtp: verifyWithOtp,
  }
}