import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { URLS, APPLICATION_GLOBAL_SLUG, createMarkup, stripHtml } from "src/_config";
import { useCmsApi } from "src/_common/hooks/actions/cms/cmsApiHook";
import { toast } from "react-toastify";
import parse from 'html-react-parser'

export default function AreaOfPracticePage() {

    const cmsApi = useCmsApi();
    const [content, setContent] = useState<any>()

    const getCmsContentDetails = () => {
        var params = {
            slug: 'areas-of-practice'
        }

        cmsApi.callGetCmsContent(params, (message: string, resp: any) => {
            if (resp) {
                setContent(resp)
            } else {
                setContent('')
            }
        }, (message: string) => {
            toast.error(message)
        })
    }

    useEffect(() => {
        getCmsContentDetails()
    }, [])

    return (
        <React.Fragment>
            <div className="registration_homepage">
                <div className="className-action">
                    <div className="container-fluid">
                        <div className="banner3">
                            <h1>AREAS OF PRACTICE</h1>
                        </div>

                    </div>
                    <div className="container-fluid body-container-fluid">
                        <div className="row">
                            <div className="col-lg-12 col-xs-12">
                                <div className="register-para">
                                    <h3>Areas of practice</h3>
                                    {
                                    content ?
                                        <>{parse(content.long_description)}</>
                                        : <p>No Data Added</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}