import { ActionExtended } from '../../../_common/interfaces/ActionExtended';
import { ACTIONS } from '../../../_config'

export interface GlobalReducer {
  loginModalShown: boolean;
  fcmToken?: string;
  deviceOS?: string;
}

const initialState: GlobalReducer = {
  loginModalShown: false,
  fcmToken: undefined,
  deviceOS: 'unknown',
};

const globalsReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.GLOBAL.SET_LOGIN_MODAL:
      return {
        ...state,
        loginModalShown: action.payload,
      };
    case ACTIONS.GLOBAL.SET_FCM_TOKEN:
      return {
        ...state,
        fcmToken: action.payload,
      };
    case ACTIONS.GLOBAL.SET_DEVICE_OS:
      return {
        ...state,
        deviceOS: action.payload,
      };
    default:
      return state;
  }
};

export default globalsReducer;
