import React from 'react';

const LazyRegistration = React.lazy(() =>
  import('./registration')
);

const registration = (props: Record<string, any>) => (
  <React.Suspense fallback={<h1>Loading...</h1>}>
    <LazyRegistration {...props} />
  </React.Suspense>
);

export default registration