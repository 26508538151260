import React from 'react';

const LazyClaimDetailsPage= React.lazy(() =>
  import('./claimDetail')
);

const ClaimDetailsPage = (props: Record<string, any>) => (
  <React.Suspense fallback={<h1>Loading...</h1>}>
    <LazyClaimDetailsPage {...props} />
  </React.Suspense>
);

export default ClaimDetailsPage