import React, { useEffect, useState } from 'react';
import Sidebar from 'src/components/common/Sidebar';
import Footer from 'src/components/common/Footer';
//import RodeoGamePage from 'src/components/events/rodeoGame/rodeoGamePage';
import { useAppUserDetailsSelector } from 'src/_common/hooks/selectors/userSelector';
import { RODEO_PAGE_NAME, STORAGE } from 'src/_config';
import { APPLICATION_NAME } from 'src/_config';
import { Link } from 'react-router-dom';
import { URLS } from 'src/_config';
import ContactUsPage from 'src/components/cms/contactUs/contactUsPage';

export default function ContactUsContainer() {

    const userDetails = useAppUserDetailsSelector()
    const userDetail = localStorage.getItem(STORAGE);
    const [ reCaptchaKey, setReCaptchaKey ] = useState<string | null>(null)
    
    useEffect(() => {
        const prevTitle = document.title
        document.title = `${APPLICATION_NAME} : Contact Us`
        return () => {
            document.title = prevTitle
        }
    }, [])

    useEffect(() => {
        setReCaptchaKey('6LfwycghAAAAAL8z5qCIpRZLiHSft3rLzENEcKnU')
    })

    return (
        <React.Fragment>
            <Sidebar page={RODEO_PAGE_NAME.EVENT_LIST} />
            <section className="main-container">
                <ContactUsPage/>
            </section>
            <Footer/>
        </React.Fragment>
    )
}
