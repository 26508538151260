import React from 'react';
import { Modal } from 'react-bootstrap';

interface WiningCarModalProps {
    shouldShow: boolean;
    setShow: Function;
    step: any;
}

export default function CreateClaimInfoModal({ shouldShow, setShow, step }: WiningCarModalProps) {

    const closeModal = () => {
        setShow(false)
    }


    return (
        <React.Fragment>
            <Modal
                show={shouldShow}
                backdrop="static"
                // onHide={() => onClose()}
                keyboard={false}
                className="dark-modal"
                centered
                contentClassName='custom-modal'
            >
                {
                    step == '3' ?
                        <>
                            <Modal.Header>
                                <h2 className="modal-header-text">Documents to upload</h2>
                                <button type="button" className="btn-close" onClick={closeModal}></button>
                            </Modal.Header>
                            <Modal.Body bsPrefix={'main-container'}>
                                <div className="modal-body comp-box-wrap">
                                    <ul>
                                        <li>Your energy contract(s).</li>
                                        <li>At least 1 bill for every contract.</li>
                                    </ul>
                                    <p>If the amount you pay varies, then please add more bills to get a more accurate calculation.</p>
                                    <b>Broker evidence</b>
                                    <p className='mb-0'>LOA (letter of authority) you gave your broker.</p>
                                    <p className='mb-0'>Email correspondence with your broker.</p>
                                    <p className='mb-0'>Recorded calls.</p>
                                    <p>*Your broker might also be named on your contract</p>
                                    <p className='mb-0'>If you require assistance</p>
                                    <p className='mb-0'><a href='/cms/contact-us' target='_blank'><i className="fa-solid fa-message nav-link" style={{color: 'black'}}></i></a> Send us a message, or</p>
                                    <p><a href="tel:0330 122 6228"><i className="fa-solid fa-phone nav-link" style={{color: 'black'}}></i></a> Call the helpline 0330 122 6228</p>

                                </div>
                            </Modal.Body>
                        </>
                        :
                        <>
                            <Modal.Header>
                                <h2 className="modal-header-text">Claims</h2>
                                <button type="button" className="btn-close" onClick={closeModal}></button>
                            </Modal.Header>
                            <Modal.Body bsPrefix={'main-container'}>
                                <div className="modal-body comp-box-wrap">
                                    <p>Claims are made against the supplier not the broker. <br /><br />
                                        You need to make separate claims for every supplier contract. <br /><br />
                                        If you used British Gas for gas and EON for electric at the same time it is two claims, even if you used the same broker.</p>
                                </div>
                            </Modal.Body>
                        </>
                }

            </Modal>
        </React.Fragment>
    )
}