import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useToaster } from 'src/_common/hooks/actions/common/appToasterHook'
import { useEventApi } from 'src/_common/hooks/actions/event/appEventApiHook'
import { useAuthStatus } from 'src/_common/hooks/auth/authHook'
import { URLS } from 'src/_config'

export default function CodePageComponent () {

    const { code } = useParams<any>()
    const eventApi = useEventApi()
    const history = useHistory()
    const toast = useToaster()
    const isAuth = useAuthStatus()

    const getShortCodeDetails = () => {
        eventApi.callGetShortCodeDetails(
            {code},
            (message:any, resp:any)=>{
                if(isAuth) {
                    history.push('/create-claim-1')
                }
                else {
                    history.push(URLS.REGISTRATION)
                }
            },(message:any, resp:any)=>{
                toast.error(message)
            }
        )
    }    

    useEffect(()=>{
        if(!code) {
            history.push(URLS.HOME)
        }
        else {
            getShortCodeDetails()
        }
    },[])

    return (
        <>
            <div className="main-container not-found-wrap">
                <h2>Please Wait...</h2>
            </div>
            {/* <p className="home-link">
                <Link to={URLS.HOME}>Go to Home</Link>
            </p> */}
        </>
    )
}
