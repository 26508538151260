import { useDispatch } from 'react-redux'
import { ACTIONS } from 'src/_config'

export function useAppEventAction() {

  const dispatch = useDispatch()

  const pushstep1details = (data: any) => {
    dispatch({
      type: ACTIONS.EVENT.STEP1_DETAILS,
      payload: data
    })
  }

  const resetstep1details = () => {
    dispatch({
      type: ACTIONS.EVENT.RESET_STEP1_DETAILS,
      payload: null
    })
  }

  const resetShortCodeDetails = () => {
    dispatch({
      type: ACTIONS.EVENT.RESET_SHORT_CODE_DETAILS,
      payload: null
    })
  }

  const showAnnaModal = (data: any) => {
    dispatch({
      type: ACTIONS.EVENT.SHOW_ANNA_MODAL,
      payload: data
    })
  }

  return {
    pushstep1details,
    resetstep1details,
    resetShortCodeDetails,
    showAnnaModal,
  }
}