import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL } from '../_config';
import { CallApi } from './api/callApi';

// export function* getEventList(action: any): any {
//     try {
//         const data = action.payload;
//         const resp = yield call(CallApi.GET, API_URL.EVENT.GET_EVENT_LIST, data);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             yield put({
//                 type: ACTIONS.EVENT.GET_EVENT_LIST,
//                 payload: resp.data.data
//             })
//             // yield put({
//             //     type: ACTIONS.EVENT.RODEO_EVENT_LOGIN_REQUIRED,
//             //     payload: resp.data.data
//             // })
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }

// export function* getEventGameList(action: any): any {
//     try {
//         const data = action.payload;
//         const resp = yield call(CallApi.POST, API_URL.EVENT.GET_EVENT_GAME, data, true);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             yield put({
//                 type: ACTIONS.EVENT.GET_EVENT_GAME,
//                 payload: resp.data.data.event_dtls
//             })
//             yield put({
//                 type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
//                 payload: resp.data.data.status
//             })
//             yield put({
//                 type: ACTIONS.EVENT.RODEO_EVENT_LOGIN_REQUIRED,
//                 payload: resp.data.data.rodeo_event_dtls.login_required
//             })
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }

// export function* getEventGameDetails(action: any): any {
//     try {
//         const data = action.payload;
//         const resp = yield call(CallApi.POST, API_URL.EVENT.GET_EVENT_GAME_DETAILS, data, true);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             yield put({
//                 type: ACTIONS.EVENT.GET_EVENT_GAME_DETAILS,
//                 payload: resp.data.data
//             })
//             yield put({
//                 type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
//                 payload: resp.data.data.status
//             })
//             yield put({
//                 type: ACTIONS.EVENT.RODEO_EVENT_LOGIN_REQUIRED,
//                 payload: resp.data.data.rodeo_event_dtls.login_required
//             })
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }

// export function* getUpcomingEventList(action: any): any {
//     try {
//         const data = action.payload;
//         const resp = yield call(CallApi.GET, API_URL.EVENT.GET_UPCOMING_EVENT, data);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }

// export function* joinEventGame(action: any): any {
//     try {
//         const data = action.payload;
//         const resp = yield call(CallApi.POST, API_URL.EVENT.JOIN_EVENT_GAME, data, true);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }

// export function* pickRider(action: any): any {
//     try {
//         const data = action.payload;
//         const resp = yield call(CallApi.POST, API_URL.EVENT.PICK_RIDER, data, true);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             yield put({
//                 type: ACTIONS.EVENT.SET_PICK_ID,
//                 payload: resp.data.data.id
//             })
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }

// export function* viewLeaderBoard(action: any): any {
//     try {
//         const data = action.payload;
//         const resp = yield call(CallApi.POST, API_URL.EVENT.LEADERBOARD, data, true);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             yield put({
//                 type: ACTIONS.EVENT.LEADERBOARD,
//                 payload: resp.data.data
//             })
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }

// export function* tvImageDetails(action: any): any {
//     try {
//         const data = action.payload;
//         const resp = yield call(CallApi.GET, API_URL.TV_IMAGE.DETAILS, data);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             console.log(resp)
//             yield put({
//                 type: ACTIONS.TV_IMAGE.DETAILS,
//                 payload: resp.data.data
//             })
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }

// export function* saveVote(action: any): any {
//     try {
//         const data = action.payload;
//         const resp = yield call(CallApi.POST, API_URL.EVENT.SAVE_VOTE, data, true);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }

// export function* getGameSettings(action: any): any {
//     try {
//         const data = action.payload;
//         const resp = yield call(CallApi.GET, API_URL.EVENT.GET_GAME_SETTINGS, data, true);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             // yield put({
//             //     type: ACTIONS.EVENT.GET_EVENT_LIST,
//             //     payload: resp.data.data
//             // })
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }

// export function* saveColor(action: any): any {
//     try {
//         const data = action.payload;
//         const resp = yield call(CallApi.POST, API_URL.EVENT.SAVE_COLOR, data, true);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             // yield put({
//             //     type: ACTIONS.EVENT.GET_EVENT_LIST,
//             //     payload: resp.data.data
//             // })
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }

export function* clownLanding(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.EVENT.CLOWN_LANDING, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* askQuestion(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.EVENT.ASK_QUESTION, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* questionsList(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.EVENT.MY_QUESTIONS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* claimPage1(action: any): any {
    try {
        const data = action.payload;
        let id = data.id? data.id : null;
        delete data.id;
        const resp = yield call(CallApi.POST, API_URL.EVENT.CLAIM_PAGE1 +(id ? '/' + id : ''), data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            yield put({
                type: ACTIONS.EVENT.STEP1_DETAILS,
                payload: resp.data.data.details
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        if (e.status == 401) {
            yield put({
                type: ACTIONS.USER.LOGOUT,
            })
        }
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* claimPage2(action: any): any {
    try {
        const data = action.payload;
        let id = data.id;
        delete data.id
        const resp = yield call(CallApi.POST, API_URL.EVENT.CLAIM_PAGE2+'/'+id, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        if (e.status == 401) {
            yield put({
                type: ACTIONS.USER.LOGOUT,
            })
        }
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* claimPage3(action: any): any {
    try {
        const data = action.payload;
        let id = data.get("id")
        data.delete("id")
        const resp = yield call(CallApi.POST, API_URL.EVENT.CLAIM_PAGE3+'/'+id, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
        // if (resp.status >= 200 && resp.status < 300 ) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        if (e.status == 401) {
            yield put({
                type: ACTIONS.USER.LOGOUT,
            })
        }
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* myClaimsList(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.EVENT.MY_CLAIMS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        if (e.status == 401) {
            console.log("myclaimcatch")
            yield put({
                type: ACTIONS.USER.LOGOUT,
            })
        }
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* claimDetails(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.EVENT.CLAIM_DETAILS + `/${data.id}`, null, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            yield put({
                type: ACTIONS.EVENT.CLAIM_DETAILS,
                payload: resp.data.data.data
            })
            // yield put({
            //     type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
            //     payload: resp.data.data.status
            // })
            // yield put({
            //     type: ACTIONS.EVENT.RODEO_EVENT_LOGIN_REQUIRED,
            //     payload: resp.data.data.rodeo_event_dtls.login_required
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        if (e.status == 401) {
            yield put({
                type: ACTIONS.USER.LOGOUT,
            })
        }
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* supplierList(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.EVENT.SUPPLIER_LIST, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        if (e.status == 401) {
            yield put({
                type: ACTIONS.USER.LOGOUT,
            })
        }
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* myIncompleteClaim(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.EVENT.INCOMPLETE_CLAIM, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        if (e.status == 401) {
            yield put({
                type: ACTIONS.USER.LOGOUT,
            })
        }
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* deleteClaim(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.EVENT.CLAIM_DELETE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        if (e.status == 401) {
            yield put({
                type: ACTIONS.USER.LOGOUT,
            })
        }
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* downloadZip(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.EVENT.CLAIM_DOCUMENTS_ZIP, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        if (e.status == 401) {
            yield put({
                type: ACTIONS.USER.LOGOUT,
            })
        }
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* contactUs(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.EVENT.CONTACT_US, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        if (e.status == 401) {
            yield put({
                type: ACTIONS.USER.LOGOUT,
            })
        }
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getShortCodeDetails(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.EVENT.SHORT_CODE_DETAILS + "/" + data.code, null, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            yield put({
                type: ACTIONS.EVENT.SHORT_CODE_DETAILS,
                payload: resp.data.data.data
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        if (e.status == 401) {
            yield put({
                type: ACTIONS.USER.LOGOUT,
            })
        }
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getCompanyNames(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.EVENT.GET_COMPANY, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            yield put({
                type: ACTIONS.EVENT.GET_COMPANY,
                payload: resp.data.data.data
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        if (e.status == 401) {
            yield put({
                type: ACTIONS.USER.LOGOUT,
            })
        }
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getCompanyList(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.EVENT.COMPANY_LIST, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            yield put({
                type: ACTIONS.EVENT.COMPANY_LIST,
                payload: resp.data.data.data
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        if (e.status == 401) {
            yield put({
                type: ACTIONS.USER.LOGOUT,
            })
        }
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* removeFile(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.EVENT.FILE_REMOVE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        if (e.status == 401) {
            yield put({
                type: ACTIONS.USER.LOGOUT,
            })
        }
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* uploadMoreDocument(action: any): any {
    try {
        const data = action.payload;
        // console.log(data)
        let id = data.get("id")
        console.log({id})
        data.delete("id")
        const resp = yield call(CallApi.POST, API_URL.EVENT.UPLOAD_MORE_DOCUMENT + "/" + id, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        if (e.status == 401) {
            yield put({
                type: ACTIONS.USER.LOGOUT,
            })
        }
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* commentAdd(action: any): any {
    try {
        const data = action.payload;
        // console.log({data})
        let id = data.id
        // console.log({id})
        delete data.id
        const resp = yield call(CallApi.POST, API_URL.EVENT.COMMENT_ADD + "/" + id, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        if (e.status == 401) {
            yield put({
                type: ACTIONS.USER.LOGOUT,
            })
        }
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* commentList(action: any): any {
    try {
        const data = action.payload;
        let id = data.id
        // console.log({id})
        delete data.id
        const resp = yield call(CallApi.GET, API_URL.EVENT.COMMENT_LIST + "/" + id, null, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        if (e.status == 401) {
            yield put({
                type: ACTIONS.USER.LOGOUT,
            })
        }
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* commentDelete(action: any): any {
    try {
        const data = action.payload;
        let id = data.id
        let comment_id = data.comment_id
        // console.log({id})
        delete data.id
        delete data.comment_id
        const resp = yield call(CallApi.POST, API_URL.EVENT.COMMENT_REMOVE + "/" + id + "/" + comment_id, null, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        if (e.status == 401) {
            yield put({
                type: ACTIONS.USER.LOGOUT,
            })
        }
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getMessageList(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.EVENT.MESSAGE_LIST, null, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        if (e.status == 401) {
            yield put({
                type: ACTIONS.USER.LOGOUT,
            })
        }
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* sendMessage(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.EVENT.SEND_MESSAGE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        if (e.status == 401) {
            yield put({
                type: ACTIONS.USER.LOGOUT,
            })
        }
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* deleteMessage(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.DELETE,API_URL.EVENT.DELETE_MESSAGE+"/"+data.id, null, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    if (e.status == 401) {
        yield put({
            type: ACTIONS.USER.LOGOUT,
        })
    }
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* updateReadStatus(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST,API_URL.EVENT.UPDATE_READ_STATUS, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    if (e.status == 401) {
        yield put({
            type: ACTIONS.USER.LOGOUT,
        })
    }
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getDirectorsList(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST,API_URL.EVENT.DIRECTORS_LIST, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    if (e.status == 401) {
        yield put({
            type: ACTIONS.USER.LOGOUT,
        })
    }
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}