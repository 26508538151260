import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <footer>
      <div className="footer-panel">
        {/* <p>&copy;  2022 All rights reserved. 123claim.co.uk</p> */}
        <div className="copyright">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-7 text-md-start">
                <div className="footer__copyright__text">
                  <p>© {new Date().getFullYear()} All rights reserved 123 Claim. <br/><Link to={{pathname: "https://123claim.co.uk/terms-conditions"}} target='_blank'>Terms &amp; Conditions</Link> | <Link to={{pathname: "https://123claim.co.uk/privacy-policy"}} target='_blank'>Privacy Policy</Link> | <Link to={{pathname: "https://123claim.co.uk/cookie-policy"}} target='_blank'>Cookie Policy</Link> | <Link to={{pathname: "https://123claim.co.uk/modern-slavery-statement"}} target='_blank'>Modern Slavery Statement</Link></p>
                </div>

              </div>
              <div className="col-lg-5 text-md-end">
                <div className="d-block">
                  <div><img alt="" data-src="/images/ico.png" className=" lazyloaded" src="/images/ico.png" /><noscript><img src="/images/ico.png" alt="" /></noscript> Registration - ZB381029</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
