import { result } from 'lodash'
import moment from 'moment'
import { CHAT_DATE_TIME_FORMAT } from '.'

export const generateDaysOption = (days: number) => {
  return new Array(days).fill(1).map((x, i) => ({
    value: i + 1,
    label: String(i + 1)
  }))
}

export const destroyMaskInput = (string: string) => {
  return string.replace(/\D/g, '').substring(0, 8);
}

export const paginate = (array: any[], page_size: number, page_number: number) => {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

export const createMarkup = (textString: string) => {
  return { __html: textString };
}

export const getChatTime = (postTime: any) => {
  var currentTime = moment();
  var postedTime = moment(postTime, 'DD-MMMM-YYYY hh:mm a').toDate();
  var timeDiff = currentTime.diff(postedTime, 'minutes') //minutes hours

  let result;
  switch (true) {
    case timeDiff <= 60 * 24:
      result = moment(postTime, 'DD-MMMM-YYYY hh:mm a').format(CHAT_DATE_TIME_FORMAT.DISPLAY_DATE_WITH_TIME)  //12th July,2021 10:20PM
      break;
    case timeDiff > 60 * 24:
      result = moment(postTime, 'DD-MMMM-YYYY hh:mm a').format(CHAT_DATE_TIME_FORMAT.DISPLAY_DAY_TIME)  //monday 10:20PM
      break;
  }
  return result;
}

//Remove html element from string
export const stripHtml = (html: any) => {
  // Create a new div element
  var temporalDivElement = document.createElement("div");
  // Set the HTML content with the providen
  temporalDivElement.innerHTML = html;
  // Retrieve the text property of the element (cross-browser support)
  return temporalDivElement.textContent || temporalDivElement.innerText || "";
}

export const generateYearsOption = (numYears: number, ignoreLastYears: number) => {
  return new Array(numYears).fill(1).map((y, i) => {
    let yt = moment().subtract((i + ignoreLastYears), 'year').format('YYYY')
    return {
      value: parseInt(yt),
      label: yt,
    }
  })
}

// export const extractErrorMessage1 = (error: any, defaultMessage = 'Please try again') => {
//   if (typeof error === 'string') {
//     return error
//   } else {
//     if (error && error.data && error.data.errors && Object.keys(error.data.errors).length) {
//       return error.data.errors[Object.keys(error.data.errors)[0]];
//     } else if (error && error.data && error.data.msg) {
//       return error.data.msg
//     } else if (error && error.msg) {
//       return error.msg
//     } else {
//       return defaultMessage
//     }
//   }
// }

export const extractErrorMessage = (e: any, defaultMessage = 'Please try again') => {
  if (e && e.data && e.data.length) {
    var err = e.data;
    var errArrays = Object.values(err[0].errors);
    var msg = '';
    errArrays.map(x => {

      msg = msg + ' ' + x;
    })
    return msg;
  } else if (typeof (e.data) == 'object') {
    var keys = Object.keys(e.data);
    var values = Object.values(e.data);
    var msg = '';
    if (values && values.length) {
      values.map((x: any, i: number) => {
        if (typeof x == 'string') {
          msg = x;
          // return x;
        }
        else {
          let errorvalues = Object.values(x)
          if (errorvalues && errorvalues.length) {
            errorvalues.map((k: any, index: number) => {
              msg = msg + (index + 1) + ' : ' + k + '\n' + '\n';
            })
          }
        }
      })
      return msg;
    }
    return defaultMessage
  }
  else if (typeof (e) === 'string') {
    return e
  }
  else {
    return defaultMessage
  }
}

//Remove empty / undefined key from object/payload
export const removeEmptyObjectKey = (obj: any) => {
  Object.keys(obj).forEach((k) => (!obj[k] && obj[k] !== undefined) && delete obj[k]);
  return obj;
};

// Get specific key Value from array of object
export const getValueFromArrayOfObject = (arr: any[], key: string) => {
  const resObject = arr.find(item => item.key === key);
  if (resObject && Object.keys(resObject).length) {
    return resObject.value;
  } else {
    return null;
  }
}


export const generateLink = (link: string) => {
  return `${window.location.origin}${link}/`
}

export const getRandomName = (prefix: string = '', suffix: string = '') => {
  let random = `${new Date().getTime()}_${Math.ceil(Math.random() * 1000)}`
  return `${prefix}${random}${suffix}`
}

export const extractHtmlString = (str: string) => {
  return str.replace(/<[^>]+>/g, '')
}

export const trimTo = (str: string, num: number, appendDots: boolean = false) => {
  return str && str.length > num ? appendDots ? `${str.substr(0, num)}...` : str.substr(0, num) : str
}

export const getYearString = (month: number) => {
  if (!month) {
    return 'N/A'
  }
  let year = Math.floor(month / 12)
  let remMonth = month % 12

  let str = ''
  if (year) {
    str += `${year} ${year > 1 ? 'years' : 'year'}`
  }
  if (month) {
    if (str && str.length) {
      str += ' and '
    }
    str += `${remMonth} ${remMonth > 1 ? 'months' : 'month'}`
  }
  return str;
}

export const getFileFromDataUrl = (dataUrl: string) => {
  if (!dataUrl) {
    return null
  }
  let arr = dataUrl.split(',');
  let mime = null
  if (arr && arr.length) {
    let parts = arr[0].match(/:(.*?);/)
    mime = parts && parts.length ? parts[1] : null
  }
  if (!mime) {
    return null
  }
  let bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], getRandomName('image', `.${mime.split('/')[1]}`), { type: mime });
}

export const getVisibleData = (data: any[] | undefined, key: string) => {
  const result = data?.filter((x) => x.key == key)
  return result && result.length && result[0].value ? result[0].value : 0;
}

export const getNameInitials = (name: string | undefined | null) => {
  if (!name) {
    return 'NA'
  }
  // return name.split(' ').map(x => x.charAt(0)).slice(0, 2).join('').toUpperCase();
  return name.substring(0, 2).toUpperCase();
}

export const getRoomcategoryInitials = (name: string | undefined | null) => {
  if (!name) {
    return 'NA'
  }
  return name.split(' ').map(x => x.charAt(0)).slice(0, 2).join('').toUpperCase();
}

export const getBooleanStatus = (value: number | null | undefined) => {
  if (value == 0) {
    return false;
  } else if (value == 1) {
    return true;
  } else {
    return false;
  }
}

export const getDisableStatus = (value: number | null) => {
  if (value == 0) {
    return true;
  } else if (value == 1) {
    return false;
  } else {
    return true;
  }
}

export const getBooleanToValueStatus = (value: boolean | undefined) => {
  if (value == true) {
    return 1;
  } else {
    return 0;
  }
}

export const getStaticColor = (value: string) => {
  let result = '#ffffff';
  switch (value) {
    case 'A':
    case 'a':
      result = '#c80000'
      break;
    case 'G':
    case 'g':
      result = '#60ae60'
      break;
    case 'R':
    case 'r':
      result = '#cbc100'
      break;
    default:
      result = '#ffffff'
      break;
  }
  return result;
}

// program to convert first letter of a string to uppercase
export const capitalizeFirstLetter = (str: string) => {
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
  return capitalized;
}

// Replace Comma(,) with space in the string value
export const replaceCommaSeparator = (str: string) => {
  const toReplace = str.trim().replace(",", " ");
  return toReplace;
}

// Get Singular plural string based on count, like : 1 => user, 2 => users
export const getSingularPluralString = (count: number, str: string) => {
  let result: string;
  if (count > 1) {
    result = count + " " + str + "s"
  } else if (count <= 1) {
    result = count + " " + str
  } else {
    result = "0 " + str
  }
  return result;
}

//1 => Available, 2 => Away, 3 => DND, 4 => Invisible
export const getStatusColor = (value: any) => {
  let result;
  switch (value) {
    case 1:
    case '1':
      result = '#00C800'
      break;
    case 2:
    case '2':
      result = '#f1b44c'
      break;
    case 3:
    case '3':
      result = '#ca2a2a'
      break;
    case 4:
    case '4':
      result = '#ccc'
      break;
    default:
      result = '#ffffff'
      break;
  }
  return result;
}

// Function for check isAdmin status based on 0 or 1
export const isAdmin = (value: any) => {
  let status: boolean = false;
  switch (value) {
    case 1:
    case '1':
      status = true;
      break;
    case 2:
    case '2':
      status = true;
      break;
    case 3:
    case '3':
      status = true;
      break;
    default:
      break;
  }
  return status;
}


// Function for sorting any array of object based on any object property
// Primer means => parseInt or parseFloat etc..
export const sort_by = (field: string, reverse: any, primer: any) => {

  const key = primer ?
    function (x: any) {
      return primer(x[field])
    } :
    function (x: any) {
      return x[field]
    };

  reverse = !reverse ? 1 : -1;

  return function (a: any, b: any) {
    return a = key(a), b = key(b), reverse * (<any>(a > b) - <any>(b > a));
  }
}

export const calculateMinTime = (date: any) => {
  let isToday = moment(date).isSame(moment(), 'day');
  if (isToday) {
    let nowAddOneHour = moment(new Date()).add({ minutes: 5 }).toDate();
    return nowAddOneHour;
  }
  return moment().startOf('day').toDate();
}

//Generate typing string
export const typingStringGenerate = (typing: any[]) => {
  let text = typing && typing.length ? typing.map((x: any) => x.userInfo) : null;
  const tempString = text ? text.toString().trim().split(",").join(" & ") : null;
  return tempString;
}

//Get Event Status based on status id
export const getEventStatusText = (status: number) => {
  let result: string = '';
  switch (status) {
    case 0:
      result = 'Not Started'
      break;
    case 1:
      result = 'Ongoing'
      break;
    case 2:
      result = 'End'
      break;
    default:
      break;
  }
  return result;
}

// Function wiill return boolean if Joining truck is assign manualy/Automatic
//1 => Automatic ; 2 => Manual
export const isTruckAssignManualyOrAutomatic = (status: number) => {
  let result: boolean = true;
  switch (status) {
    case 1:
      result = false;
      break;
    case 2:
      result = true;
      break;
    default:
      break;
  }
  return result;
}

// If x = 50.00 then output will be 50, if x= 50.32 then output will be 50.32
export const removeTrailingZero = (value: number) => {
  return (+value).toFixed(2).replace(/(\.0+|0+)$/, '')
}

//Check ios/android

export const detectDevice = () => {
  var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  if (isIOS) {
    return true;
  } else {
    return false;
  }
}


export const getOperatingSystem = (): string => {
  var userAgent = navigator.userAgent || navigator.vendor || '';
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
      return 'android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'ios';
  }

  if (navigator.appVersion.indexOf('Win') !== -1) {
      return 'Windows OS';
  }

  if (navigator.appVersion.indexOf('Mac') !== -1) {
      return 'MacOS';
  }

  if (navigator.appVersion.indexOf('X11') !== -1) {
      return 'UNIX OS';
  }

  if (navigator.appVersion.indexOf('Linux') !== -1) {
      return 'Linux OS';
  }

  return "unknown";
}

export const addCommaToAmount = (val: any) => {
  let a = val.split(".")
  let b = ""
  if(a.length==2) {
    b=a[1]
  }
  if(a[0].length<4) {
    return val
  }
  else {
    let x = ""
    console.log(a[0].length)
    for(let i=a[0].length-1;i>=0;i-=3) {
      if((i-2)>0) {
        x=a[0].substring(i-2,i+1)+(i!=a[0].length-1 ? "," : "")+x
      }
      else {
        x=a[0].substring(0,i+1)+","+x
      }
    }
    return x+(b?"."+b:"")
  }
}

export const changeString = (str: string, len: number) => {
  if (str.length > 0 && str.length > len) {
    return str.substr(0, len) + "..."
  } else {
    return str
  }
}

export const extractNumbersOnly = (str: string) => {
  let x = ""
  for(let i=0;i<str.length;i++) {
    let c = str.charAt(i)
    if(c=='0'||c=='1'||c=='2'||c=='3'||c=='4'||c=='5'||c=='6'||c=='7'||c=='8'||c=='9') {
      x=x+c
    }
  }
  return x
}