import React, { useEffect, useState } from 'react';
import Sidebar from 'src/components/common/Sidebar';
import Footer from 'src/components/common/Footer';
//import RodeoGamePage from 'src/components/events/rodeoGame/rodeoGamePage';
import { useAppUserDetailsSelector } from 'src/_common/hooks/selectors/userSelector';
import { RODEO_PAGE_NAME, STORAGE } from 'src/_config';
import { APPLICATION_NAME } from 'src/_config';
import { Link } from 'react-router-dom';
import { URLS } from 'src/_config';
import BookMyAppointmentsPage from 'src/components/cms/bookMyAppointments/bookMyAppointmentsPage';
import { useCmsApi } from 'src/_common/hooks/actions/cms/cmsApiHook';

export default function BookMyAppointmentsContainer() {

    const userDetails = useAppUserDetailsSelector()
    const userDetail = localStorage.getItem(STORAGE);
    const cmsApi = useCmsApi();
    const [content, setContent] = useState<any>()

    const getCmsContentDetails = () => {
        var params = {
            slug: 'book-an-appointment'
        }

        cmsApi.callGetCmsContent(params, (message: string, resp: any) => {
            if (resp) {
                setContent(resp)
                console.log(content)
            } else {
                setContent('')
            }
        }, (message: string) => {
            // toast.error(message)
        })
    }

    useEffect(() => {
        const prevTitle = document.title
        document.title = `${APPLICATION_NAME} : Book an Appointment`
        return () => {
            document.title = prevTitle
        }
    }, [])

    useEffect(() => {
        // getCmsContentDetails()
    })

    return (
        <React.Fragment>
            <Sidebar page={RODEO_PAGE_NAME.EVENT_LIST} />
            <section className="main-container">
                <BookMyAppointmentsPage/>
            </section>
            <Footer/>
        </React.Fragment>
    )
}
