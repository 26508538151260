import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { EventGameDetails } from 'src/_common/interfaces/models/event';
import { StateExtended } from '../../interfaces/StateExtended'

export function useAppEvenListSelector() {
    const eventList = useSelector((state: StateExtended) => state.event.getEventList)
    return eventList;
}

export function useAppEventGameListSelector() {
    const eventGameList = useSelector((state: StateExtended) => state.event.getEventGameList)
    return eventGameList;
}

export function useAppEventGameDetailSelector() {
    const eventGameDetails = useSelector((state: StateExtended) => state.event.getEventGameDetails)
    return eventGameDetails;
}

export function useAppEventStatusMessageSelector() {
    const statusMessage = useSelector((state: StateExtended) => state.event.getEventStatusMessage)
    return statusMessage;
}


export function useAppEventLeaderBoardSelector() {
    const leaderBoard = useSelector((state: StateExtended) => state.event.getLeaderBoard)
    return leaderBoard;
}

export function useAppTvImageSelector() {
    const tvImage = useSelector((state: StateExtended) => state.event.getTvImageDetails)
    return tvImage;
}

export function useAppGameWiningTruckDetailsSelector() {
    const winingTruck = useSelector((state: StateExtended) => state.event.getWiningTruckDetails)
    return winingTruck;
}

export function useAppWiningTruckModalOpen() {
    const winingModal = useSelector((state: StateExtended) => state.event.winingTruckModalShow)
    return winingModal;
}
export const useAppNextEventRouteSelector = () => {
    const activeRoute = useSelector((state: StateExtended) => state.event.fromRoute)
    return activeRoute;
}
export const useAppPickIdsSelector = () => {
    const pick_id = useSelector((state: StateExtended) => state.event.pick_id)
    return pick_id;
}
export const useAppRodeoEventLoginRequiredSelector = () => {
    const login_required = useSelector((state: StateExtended) => state.event.login_required)
    return login_required;
}

export const useAppStep1DetailsSelector = () => {
    const step1_details = useSelector((state: StateExtended) => state.event.step1_details)
    return step1_details;
}

export const useAppClaimDetailsSelector = () => {
    const claim_details = useSelector((state: StateExtended) => state.event.claim_details)
    return claim_details;
}

export const useAppShortCodeDetailsSelector = () => {
    const short_code_details = useSelector((state: StateExtended) => state.event.short_code_details)
    return short_code_details;
}

export const useAppCompanyListSelector = () => {
    const company_names = useSelector((state: StateExtended) => state.event.company_names)
    return company_names;
}

export const useAppCompanyNameListSelector = () => {
    const company_list = useSelector((state: StateExtended) => state.event.company_list)
    return company_list;
}

export const useAppShowAnnaModalSelector = () => {
    const show_anna_modal = useSelector((state: StateExtended) => state.event.show_anna_modal)
    return show_anna_modal;
}