// @ts-nocheck
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { URLS } from './_config';
import requireNoAuth from './_common/hoc/reqNoAuth';
import requireAuth from './_common/hoc/reqAuth';
import reqLayout from './_common/hoc/layout';
import Login from './containers/login';
import Registration from './containers/registration';
import ClaimPage1 from './containers/claimPage1';
import MyProfile from './containers/myProfile';
import AboutUsContainer from './containers/cms/aboutUs/aboutUs';
import LegalContainer from './containers/cms/legal/legal';
import HowToPlayContainer from './containers/cms/howToPlay/howToPlay';
import TermOfUseContainer from './containers/cms/termOfUse/termOfUse';
import Page404 from './containers/page-404';
import CacheBuster from './CacheBuster';
//import RodeoTvImageContainer from './containers/tv-image/tv-image';
import HomePageContainer from './containers/RodeoGame/rodeoGame';
import myQuestions from './containers/questions';
import ClaimPage2 from './containers/claimPage2/claimPage2';
import Otp from './containers/otp';
import MyClaimsPage from './containers/myClaims';
import ClaimDetailsPage from './containers/claimDetails';
import OtpDetailsPage from './containers/otpDetails';
import BookMyAppointmentsContainer from './containers/cms/bookMyAppointments/bookMyAppointments';
import OurTeamContainer from './containers/cms/ourTeam/ourTeam';
import AreaOfPracticeContainer from './containers/cms/areaOfPractice/areaOfPractice';
import ContactUsContainer from './containers/cms/contactUs/contactUs';
import CreateClaimStep1Page from './containers/createClaimStep1/createClaimStep1';
import CreateClaimStep2Page from './containers/createClaimStep2';
import CreateClaimStep3Page from './containers/createClaimStep3/createClaimStep3';
import CodePage from './containers/codePage/codePage';
import AppNotification from './_common/components/elements/app-notification/appNotification';
import {gapi} from 'gapi-script';


const clientId="276354286878-ac7dnk8hffe5hjt2da2h5eh188k1p5g0.apps.googleusercontent.com"

function App() {

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope:""
      })
    }

    gapi.load('client:auth2', start)
  })

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <React.Fragment>
            <AppNotification />
            <Router>
              <Switch>
                <Route exact path={URLS.HOME} component={(HomePageContainer)} />
                {/* <Route exact path={URLS.HOME} component={() => (
                    <Redirect to={URLS.EVENT.MY_CLAIMS}/>
                  )} /> */}
                <Route exact path={URLS.CMS.BOOK_MY_APPOINTMENTS} component={(BookMyAppointmentsContainer)} />
                <Route exact path={URLS.CMS.OUR_TEAM} component={(OurTeamContainer)} />
                <Route exact path={URLS.CMS.AREA_OF_PRACTICE} component={(AreaOfPracticeContainer)} />
                <Route exact path={URLS.CMS.CONTACT_US} component={(ContactUsContainer)} />
               
                {/* <Route exact path={URLS.EVENT.QUESTION_LIST} component={(QuestionListContainer)} /> */}
                <Route exact path={URLS.USER.PROFILE} component={requireAuth(MyProfile)} />
                <Route exact path={URLS.CMS.ABOUT_US} component={(AboutUsContainer)} />
                <Route exact path={URLS.CMS.LEGAL} component={(LegalContainer)} />
                <Route exact path={URLS.LOGIN} component={requireNoAuth(Login)} />
                <Route exact path={URLS.REGISTRATION} component={requireNoAuth(Registration)} />
                <Route exact path={URLS.VERIFY} component={(OtpDetailsPage)} />
                {/* <Route exact path={URLS.VERIFY} component={requireNoAuth(OtpDetailsPage)} /> */}
                {/* <Route exact path={URLS.EVENT.CLAIM_PAGE1} component={requireAuth(ClaimPage1)} /> */}
                {/* <Route exact path={URLS.EVENT.CLAIM_PAGE2} component={requireAuth(ClaimPage2)} /> */}
                <Route exact path={URLS.EVENT.CLAIM_PAGE1} component={requireAuth(CreateClaimStep1Page)} />
                <Route exact path={URLS.EVENT.CLAIM_PAGE2} component={requireAuth(CreateClaimStep2Page)} />
                <Route exact path={URLS.EVENT.CLAIM_PAGE3} component={requireAuth(CreateClaimStep3Page)} />
                <Route exact path={URLS.CODE} component={(CodePage)} />
                <Route exact path={URLS.EVENT.MY_CLAIMS} component={requireAuth(MyClaimsPage)} />
                <Route exact path={URLS.EVENT.CLAIM_DETAILS} component={requireAuth(ClaimDetailsPage)} />
                <Route exact path={URLS.CMS.TERM_OF_USE} component={(TermOfUseContainer)} />
                <Route exact path={URLS.CMS.HOW_TO_PLAY} component={(HowToPlayContainer)} />
                <Route exact path={URLS.EVENT.MY_QUESTIONS} component={(myQuestions)} />
                <Route exact path={URLS.OTP} component={(requireNoAuth(Otp))} />
                <Route path="*" component={reqLayout(Page404)} />
              </Switch>
            </Router>
          </React.Fragment>
        );
      }}
    </CacheBuster>
  );
}

export default App;
