import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Sidebar from 'src/components/common/Sidebar'
import Footer from 'src/components/common/Footer'
import ClaimDetailsPage from 'src/components/claimDetails/claimDetails'
import { APPLICATION_NAME, RODEO_PAGE_NAME, URLS } from '../../_config'
import CodePageComponent from 'src/components/codePage/codePageComponent'

export default function CodePage() {

  //Set Login Page Title
  useEffect(() => {
    const prevTitle = document.title
    document.title = `${APPLICATION_NAME}`
    return () => {
      document.title = prevTitle
    }
  }, [])

  return (
    <React.Fragment>
      <Sidebar page={RODEO_PAGE_NAME.LOGIN} />
      <CodePageComponent />
    </React.Fragment>
  )
}


