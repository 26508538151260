import { useDispatch } from 'react-redux'
import { ACTIONS } from '../../../../_config'

export function useAppFileProgressAction() {

  const dispatch = useDispatch()

  const createFileProgress = (data: any) => {
    // console.log(data)
    dispatch({
      type: ACTIONS.FILE.CREATE_LISTS,
      payload: data
    })
  }

  const resetFileProgress = () => {
    dispatch({
      type: ACTIONS.FILE.RESET_LISTS
    })
  }

  return {
    createFileProgress,
    resetFileProgress
  }
}