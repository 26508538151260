import { ActionExtended } from 'src/_common/interfaces/ActionExtended';
import { ACTIONS } from 'src/_config';

export interface FileProgressReducer {
    progressInfoList: any;
}

const initialState: FileProgressReducer = {
    progressInfoList: null
};

const fileProgressReducer = (state = initialState, action: ActionExtended) => {
    switch (action.type) {
        case ACTIONS.FILE.GET_LISTS:
            return {
                ...state,
                progressInfoList: action.payload
            };
        case ACTIONS.FILE.CREATE_LISTS:
            // console.log({ data: action.payload })
            // let data = action.payload
            // let x: any = []
            // if(state.progressInfoList && state.progressInfoList.length){
            //     if ((data && data.length) > (state.progressInfoList && state.progressInfoList.length)) {
            //         x = data
            //         for(var i = 0, l = x.length; i < l; i++) {
            //             for(var j = 0, ll = state.progressInfoList.length; j < ll; j++) {
            //                 if(x[i].fileName === state.progressInfoList[j].fileName) {
            //                     x.splice(i, 1, state.progressInfoList[j]);
            //                     break;
            //                 }
            //             }
            //         }
            //     }
            //     else{
            //         x = state.progressInfoList
            //         for(var i = 0, l = x.length; i < l; i++) {
            //             for(var j = 0, ll = data.length; j < ll; j++) {
            //                 if(x[i].fileName === data[j].fileName) {
            //                     x.splice(i, 1, data[j]);
            //                     break;
            //                 }
            //             }
            //         }
            //     }
            // }
            // else{
            //     x = data
            // }
            // if ((data && data.length) > (state.progressInfoList && state.progressInfoList.length)) {
            //     x = data
            // }
            // else{
            //     x = state.progressInfoList
            // }
            // console.log({finalX:x})
            return {
                ...state,
                progressInfoList: action.payload
                // progressInfoList: state.progressInfoList.push(action.payload)
                // progressInfoList: x
            };
        case ACTIONS.FILE.RESET_LISTS:
            return {
                ...state,
                progressInfoList: null
            };
        default:
            return state;
    }
};

export default fileProgressReducer;