// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAfAexK7w4kzWeYuINplZtLgdOZQ8PNqBs",
  authDomain: "reclaim-63c55.firebaseapp.com",
  projectId: "reclaim-63c55",
  storageBucket: "reclaim-63c55.appspot.com",
  messagingSenderId: "585920429",
  appId: "1:585920429:web:6b2a7b6c18c8db5c18fb24",
  measurementId: "G-3FWN9HTJ8Z"
};

const firebaseVapiKey = "BEH0vyOAoJ2GfstY_poueLFkoDXVduGjgpgtm8bCWhZaZO07oAbhau1sBRnK-ELFAock0f2m9FFYFJNc1Hc8L-Q";

// Initialize Firebase
initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const messaging = getMessaging();




const registerFCMServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("./firebase-messaging-sw.js", {
          scope: '/',
        })
      .then(function (registration) {
        // navigator.serviceWorker.addEventListener("message", (message) => console.log('push  == ', message));
        console.log(
          "Registration successful, scope is:",
          registration.scope
        );
      })
      .catch(function (err) {
        console.log("Service worker registration failed, error:", err);
      });
  }
}


export const requestForToken = () => {
    return getToken(messaging, { vapidKey: firebaseVapiKey })
      .then((currentToken) => {
        if (currentToken) {
          registerFCMServiceWorker()
          return currentToken;
          // Perform any other neccessary action with the token
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          return ''
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        return ''
      });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("received push message ===== ", payload)
      resolve(payload);
    });
});