import { SAGA_ACTIONS } from 'src/_config'
import { GetEventList, GetEventGameList, GetEventGameDetails, GetUpcomingEventList, JoinEventGame, PickRider, LeaderBoard, TvImageDetails, SaveVote, GameSettingsReq, ClownLandingReq, AskQuestionReq, MyQuestionsReq, ClaimCreateStep1Req,  ClaimDetailsReq, DeleteClaimReq, DownloadZipReq, ContactUsReq, ClaimCreateStep3Req, ClaimCreateStep2Req, GetCompanyReq, RemoveFileReq, GetCompanyListReq, CommentListAddReq, CommentDeleteReq, SendMessageReq, DeleteMessageReq, UpdateReadStatusReq, DirectorsListReq } from 'src/_common/interfaces/ApiReqRes'
import { useApiCall } from '../common/appApiCallHook'

export function useEventApi() {

    const callApi = useApiCall()

    // const eventList = (data: GetEventList, onSuccess: Function, onError: Function) => {
    //     callApi(SAGA_ACTIONS.EVENT.GET_EVENT_LIST, data, onSuccess, onError);
    // }

    // const eventGameList = (data: GetEventGameList, onSuccess: Function, onError: Function) => {
    //     callApi(SAGA_ACTIONS.EVENT.GET_EVENT_GAME, data, onSuccess, onError);
    // }

    // const eventGameDetails = (data: GetEventGameDetails, onSuccess: Function, onError: Function) => {
    //     callApi(SAGA_ACTIONS.EVENT.GET_EVENT_GAME_DETAILS, data, onSuccess, onError);
    // }

    // const upcomingEventList = (data: GetUpcomingEventList, onSuccess: Function, onError: Function) => {
    //     callApi(SAGA_ACTIONS.EVENT.GET_UPCOMING_EVENT, data, onSuccess, onError);
    // }

    // const pickRider = (data: PickRider, onSuccess: Function, onError: Function) => {
    //     callApi(SAGA_ACTIONS.EVENT.PICK_RIDER, data, onSuccess, onError);
    // }

    // const viewLeaderBoard = (data: LeaderBoard, onSuccess: Function, onError: Function) => {
    //     callApi(SAGA_ACTIONS.EVENT.LEADERBOARD, data, onSuccess, onError);
    // }

    // const joinEventGame = (data: JoinEventGame, onSuccess: Function, onError: Function) => {
    //     callApi(SAGA_ACTIONS.EVENT.JOIN_EVENT_GAME, data, onSuccess, onError);
    // }

    // const tvImageList = (data: TvImageDetails, onSuccess: Function, onError: Function) => {
    //     callApi(SAGA_ACTIONS.TV_IMAGE.DETAILS, data, onSuccess, onError, false);
    // }

    // const saveVote = (data: SaveVote, onSuccess: Function, onError: Function) => {
    //     callApi(SAGA_ACTIONS.EVENT.SAVE_VOTE, data, onSuccess, onError);
    // }

    // const getGameSettings = (data: GameSettingsReq, onSuccess: Function, onError: Function) => {
    //     callApi(SAGA_ACTIONS.EVENT.GET_GAME_SETTINGS, data, onSuccess, onError,false);
    // }

    // const saveColor = (data: SaveColorReq, onSuccess: Function, onError: Function) => {
    //     callApi(SAGA_ACTIONS.EVENT.SAVE_COLOR, data, onSuccess, onError,false);
    // }

    const askQuesion = (data: AskQuestionReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.ASK_QUESTION, data, onSuccess, onError);
    }

    const myQuestions = (data: MyQuestionsReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.MY_QUESTIONS, data, onSuccess, onError);
    }

    const clownLanding = (data: ClownLandingReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.CLOWN_LANDING, data, onSuccess, onError);
    }

    const claimPage1 = (data: ClaimCreateStep1Req, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.CLAIM_PAGE1, data, onSuccess, onError);
    }

    const createClaimStep1 = (data: ClaimCreateStep1Req, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.CLAIM_PAGE1, data, onSuccess, onError);
    }

    const claimPage2 = (data: ClaimCreateStep2Req, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.CLAIM_PAGE2, data, onSuccess, onError);
    }

    const createClaimStep2 = (data: ClaimCreateStep2Req, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.CLAIM_PAGE2, data, onSuccess, onError);
    }

    const claimPage3 = (data: ClaimCreateStep3Req, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.CLAIM_PAGE3, data, onSuccess, onError);
    }

    const createClaimStep3 = (data: ClaimCreateStep3Req, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.CLAIM_PAGE3, data, onSuccess, onError);
    }

    const myClaims = (data: AskQuestionReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.MY_CLAIMS, data, onSuccess, onError);
    }

    const claimDetails = (data: ClaimDetailsReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.CLAIM_DETAILS, data, onSuccess, onError);
    }

    const getSupplierList = (onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.SUPPLIER_LIST, null, onSuccess, onError);
    }

    const getIncompleteList = (onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.INCOMPLETE_CLAIM, null, onSuccess, onError);
    }

    const deleteCLaim = (data: DeleteClaimReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.CLAIM_DELETE, data, onSuccess, onError);
    }

    const downloadZip = (data: DownloadZipReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.CLAIM_DOCUMENTS_ZIP, data, onSuccess, onError);
    }

    const contactUs = (data: ContactUsReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.CONTACT_US, data, onSuccess, onError);
    }

    const getShortCodeDetails = ( data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.SHORT_CODE_DETAILS, data, onSuccess, onError);
    }

    const getCompanyNames = (data: GetCompanyReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.GET_COMPANY, data, onSuccess, onError);
    }

    const getCompanyList = (data: GetCompanyListReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.COMPANY_LIST, data, onSuccess, onError);
    }

    const removeFile = (data: RemoveFileReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.FILE_REMOVE, data, onSuccess, onError);
    }

    const uploadMoreDocument = (data: ClaimCreateStep3Req, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.UPLOAD_MORE_DOCUMENT, data, onSuccess, onError);
    }

    const commentAdd = (data: CommentListAddReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.COMMENT_ADD, data, onSuccess, onError);
    }

    const commentDelete = (data: CommentDeleteReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.COMMENT_REMOVE, data, onSuccess, onError);
    }

    const getCommentList = (data: CommentListAddReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.COMMENT_LIST, data, onSuccess, onError);
    }

    const getMessageList = (onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.MESSAGE_LIST, null, onSuccess, onError);
    }

    const sendMessage = (data: SendMessageReq,onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.SEND_MESSAGE, data, onSuccess, onError);
    }

    const deleteMessage = (data: DeleteMessageReq,onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.DELETE_MESSAGE, data, onSuccess, onError);
    }

    const updateReadStatus = (data: UpdateReadStatusReq,onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.UPDATE_READ_STATUS, data, onSuccess, onError);
    }

    const getDirectorsList = (data: DirectorsListReq,onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.DIRECTORS_LIST, data, onSuccess, onError);
    }
    
    return {
        // callGetEventList: eventList,
        // callGetUpcomingEventList: upcomingEventList,
        // callGetEventGameList: eventGameList,
        // callGetEventGameDetails: eventGameDetails,
        // callPickRider: pickRider,
        // callViewLeaderBoard: viewLeaderBoard,
        // callTvImageDetails: tvImageList,
        // callJoinEventGame: joinEventGame,
        // callSaveVote: saveVote,
        // callGetGameSettings: getGameSettings,
        // callSaveColor: saveColor,
        callAskQuesion: askQuesion,
        callMyQuestions: myQuestions,
        callClownLanding: clownLanding,
        callClaimPage1: claimPage1,
        callCreateClaimStep1: createClaimStep1,
        callCreateClaimStep2: createClaimStep2,
        callCreateClaimStep3: createClaimStep3,
        callMyClaims: myClaims,
        callClaimDetails: claimDetails,
        callSupplierList: getSupplierList,
        callClaimPage2: claimPage2,
        callClaimPage3: claimPage3,
        callGetIncompleteList: getIncompleteList,
        callDeleteClaim: deleteCLaim,
        callDownloadZip: downloadZip,
        callContactUs: contactUs,
        callGetShortCodeDetails: getShortCodeDetails,
        callGetCompanyNames: getCompanyNames,
        callGetCompanyList: getCompanyList,
        callRemoveFile: removeFile,
        callUploadMoreDocument: uploadMoreDocument,
        callCommentAdd: commentAdd,
        callCommentDelete: commentDelete,
        callGetCommentList: getCommentList,
        callGetMessageList: getMessageList,
        callSendMessage: sendMessage,
        callDeleteMessage: deleteMessage,
        callUpdateReadStatus: updateReadStatus,
        callGetDirectorsList: getDirectorsList,
    }
}