import React, { useEffect, useState } from 'react'
import './appNotification.css'
import { requestForToken, onMessageListener } from '../../../../_config/firebase';
import { getOperatingSystem } from '../../../../_config/functions';
import { useAppGlobalAction } from 'src/_common/hooks/actions/common/appGlobalActionHook';
import { useAppConfirm } from 'src/_common/hooks/actions/common/appConfirmHook'


const AppNotification = () => {
    // Use States and hooks
    const globalActions = useAppGlobalAction()
    const [fcmToken, setFcmToken] = useState<string>()
    const [isPanelShown, setPanelShown] = useState<boolean>(false)
    const [showNotification, setShowNotification] = useState<boolean>(true)
    const [notification, setNotification] = useState<any>()
    const appConfirm = useAppConfirm()
    // Use States and hooks
    
    // Use Effects
    useEffect(() => {
        setTimeout(() => {
            if ( !fcmToken || fcmToken.length === 0 ) {
                setPanelShown(true)
                initializePushNotifications()
                getDeviceInfo()
            } else {
                setPanelShown(false)
            }
        }, 1000);
        
    }, [fcmToken])
    // Use Effects
    
    // Functions
    const initializePushNotifications = () => {
        let clientTokenResponse = requestForToken();
        clientTokenResponse.then((token: any) => {
            console.log({fcmToken: token})
            setFcmToken(token)
            globalActions.setDeviceFcmToken(token)
        })
    }

    const getDeviceInfo = () => {
        var deviceOS: string = getOperatingSystem()
        console.log({deviceOS})
        globalActions.setDeviceOS(deviceOS)
    }

    const showNotificationModal = (title: string, body: string) => {
        appConfirm(title, body, 'Okay', 'Close', () => {
            setShowNotification(false)
        }, () => {
            setShowNotification(false)
        })
    }

    onMessageListener()
        .then((payload: any) => {
            showNotificationModal(payload?.notification?.title, payload?.notification?.body)   
            console.log(showNotification) 
        })
        .catch((err) => {
            setNotification(null)
            console.log('failed: ', err)
        });

    // Render
    return (
       
        <>
            <>
                {
                    isPanelShown
                        ? (
                            <>
                                {
                                    !fcmToken || fcmToken.length === 0
                                        ? (
                                            <div className="fb-notification-wrap">
                                                <div className="spacer"></div>
                                                <p className="fb-notificaiton-text ">Your notifications are blocked. Please allow notifications for a smoother experience.</p>
                                                <div className="spacer"></div>
                                                <span className="fb-notification-close" onClick={() => setPanelShown(false)}>&times;</span>
                                            </div>
                                        )
                                        : null
                                }
                            </>
                        )
                        : null
                }
            </>
            {/* <>
                {
                    showNotification
                        ? (
                            <>
                                {
                                    notification && notification.title && notification.body
                                        ? (
                                            <div className="fb-notification-popup">
                                                <div className="spacer"></div>
                                                <p className="fb-notificaiton-text ">Your notifications are blocked. Please allow notifications for a smoother experience.</p>
                                                <div className="spacer"></div>
                                                <span className="fb-notification-close" onClick={() => setPanelShown(false)}>&times;</span>
                                            </div>
                                        )
                                        : null
                                }
                            </>
                        )
                        : null
                }
            </> */}
        </>
    )
    // Render
}

export default AppNotification