import { API_BASE_URL, SOCKETURL } from './site_urls'

export const APPLICATION_NAME = '123 Claim'
export const APPLICATION_GLOBAL_SLUG = 'class-action'
export const STORAGE = 'RODEO-APP'
export const SELECTED_EVENT_STORAGE = `${APPLICATION_NAME}-Event`

export const INSIDERODEO_EVENT_STORAGE = `EXTERNAL-EVENT-FOR-CLASSACTION`

export const RODEPICK_NEXT_GAME_IDS = `${APPLICATION_NAME}-NEXT-GAMES`

export const IS_SHOWING_ADD_TO_HOME_SCREEN_IOS = `${APPLICATION_NAME}-IOS`

export const PER_PAGE = 10;
export const WITHOUT_PER_PAGE = 99999;

export const FILE_BASE_PATH = ''
export const APP_VERSION = process.env.REACT_APP_VERSION
export const APP_STAGE = process.env.REACT_APP_STAGE

export const DATE_FORMAT = 'MM/dd/yyyy';

export const DATE_ALL_FORMAT = {
  DATE_PICKER_FORMAT: 'MM/dd/yyyy',
  MOMENT_FORMAT: 'MM/DD/yyyy'
}

export const CHAT_DATE_TIME_FORMAT = {
  DISPLAY_DATE_WITH_TIME: 'Do MMMM, YYYY hh:mm a',
  DISPLAY_DAY_TIME: 'dddd hh:mm a'
}
export const TIME_CONFIG = {
  TIME_FORMAT: 'hh:mm a',
  TIME_INTERVALS: 5,
}


export const SOCKET_URL = SOCKETURL

export const URLS = {
  HOME: '/',
  CODE: '/entity/:code?',
  LOGIN: '/login',
  REGISTRATION: '/registration',
  VERIFY: '/verify-account',
  OTP: '/login-otp',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_OTP: '/forgot-password-otp',
  RESET_PASSWORD: '/reset-password',
  TV_IMAGE: '/tv-leaderboard',
  USER: {
    DASHBOARD: '/dashboard',
    PROFILE: '/profile'
  },
  CMS: {
    BOOK_MY_APPOINTMENTS: '/cms/book-an-appointment',
    OUR_TEAM: '/cms/our-team',
    AREA_OF_PRACTICE: '/cms/areas-of-practice',
    CONTACT_US: '/cms/contact-us',
    ABOUT_US: '/cms/about-us',
    LEGAL: '/cms/legal',
    HOW_TO_PLAY: '/cms/how-to-play',
    TERM_OF_USE: '/cms/term-of-use',
  },
  EVENT: {
    CLAIM_PAGE1: '/create-claim-1/:id?',
    CLAIM_PAGE2: '/create-claim-2/:id?',
    CLAIM_PAGE3: '/create-claim-3',
    LIST: '/events',
    EVENT_GAMES: '/event/:eventId',
    EVENT_GAMES_DETAILS: '/event/:eventId/:gameId',
    RIDER_LIST: '/event/:eventId/:gameId',
    MY_QUESTIONS: '/my-questions',
    LEADER_BOARD: {
      EVENT: '/result/:eventId',
      GAME: '/result/:eventId/:gameId',
    },
    QUESTION_LIST: '/questions',
    MY_CLAIMS: '/my-claims',
    CLAIM_DETAILS: '/claim-details/:id',
    CLAIM_DELETE: '/claim/delete'
  },
}

export const API_URL = {
  USER: {
    REGISTRATION: `${API_BASE_URL}register`,
    VERIFY: `${API_BASE_URL}verify-account`,
    LOGIN: `${API_BASE_URL}login`,
    LOGIN_WITH_OTP: `${API_BASE_URL}login-otp`,
    LOGOUT: `${API_BASE_URL}auth/logout`,
    DETAILS: `${API_BASE_URL}init`,
    UPDATE_PROFILE: `${API_BASE_URL}users/update`,
    SOCIAL_LOGIN_REGISTER: `${API_BASE_URL}social-login-register`,
    VERIFY_WITH_OTP: `${API_BASE_URL}users/send-otp`,
  },
  TV_IMAGE: {
    // DETAILS: `${API_BASE_URL}rodeo-pick/leaderboard-tvimage`
  },
  EVENT: {
    // GET_EVENT_LIST: `${API_BASE_URL}rodeo-pick/rodeo-event`,
    // GET_EVENT_GAME: `${API_BASE_URL}rodeo-pick/rodeo-event/events`,
    // GET_EVENT_GAME_DETAILS: `${API_BASE_URL}rodeo-pick/rodeo-event/event-details`,
    // PICK_RIDER: `${API_BASE_URL}rodeo-pick/rodeo-event/user-pick`,
    // LEADERBOARD: `${API_BASE_URL}rodeo-pick/rodeo-event/leaderboard`,
    // SAVE_VOTE: `${API_BASE_URL}rodeo-pick/rodeo-event/save-vote`,

    // GET_UPCOMING_EVENT: `${API_BASE_URL}rodeo-race/upcoming-events`,
    // JOIN_EVENT_GAME: `${API_BASE_URL}rodeo-race/rodeo-event/join`,
    // GET_GAME_SETTINGS: `${API_BASE_URL}screen-show/settings`,
    // SAVE_COLOR: `${API_BASE_URL}screen-show/save-color`,
    ASK_QUESTION: `${API_BASE_URL}ask-the-clown/ask-question`,
    CLAIM_PAGE1: `${API_BASE_URL}claim/create/step1`,
    CLAIM_PAGE2: `${API_BASE_URL}claim/create/step2`,
    CLAIM_PAGE3: `${API_BASE_URL}claim/create/step3`,
    INCOMPLETE_CLAIM: `${API_BASE_URL}claim/incomplete`,
    CLOWN_LANDING: `${API_BASE_URL}ask-the-clown/clown-landing`,
    MY_QUESTIONS: `${API_BASE_URL}ask-the-clown/question-list`,
    MY_CLAIMS: `${API_BASE_URL}claim/my-claim`,
    CLAIM_DETAILS: `${API_BASE_URL}claim/details`,
    SUPPLIER_LIST: `${API_BASE_URL}supplier/list`,
    CLAIM_DELETE: `${API_BASE_URL}claim/delete`,
    CLAIM_DOCUMENTS_ZIP: `${API_BASE_URL}claim/download-zip`,
    CONTACT_US: `${API_BASE_URL}contact-us`,
    SHORT_CODE_DETAILS: `${API_BASE_URL}claim/short-code-details`,
    GET_COMPANY: `${API_BASE_URL}claim/get-Company`,
    FILE_REMOVE: `${API_BASE_URL}file-remove`,
    COMPANY_LIST: `${API_BASE_URL}claim/company/list`,
    UPLOAD_MORE_DOCUMENT: `${API_BASE_URL}claim/upload-more-document`,
    COMMENT_ADD: `${API_BASE_URL}claim/comment-add`,
    COMMENT_REMOVE: `${API_BASE_URL}claim/comment-destroy`,
    COMMENT_LIST: `${API_BASE_URL}claim/comment-list`,
    MESSAGE_LIST: `${API_BASE_URL}message/list`,
    SEND_MESSAGE: `${API_BASE_URL}message/send`,
    DELETE_MESSAGE: `${API_BASE_URL}message/delete`,
    UPDATE_READ_STATUS: `${API_BASE_URL}message/cmr/update`,
    DIRECTORS_LIST: `${API_BASE_URL}claim/company/officers`,
  },
  CMS: {
    // GET_CMS: `${API_BASE_URL}rodeo-race/get-cms`,
    GET_CMS: `${API_BASE_URL}cms`,
  },
  COMMON: {
    GENDER_LIST: `${API_BASE_URL}genders`,
    COUNTRY_LIST: `${API_BASE_URL}country/list`,
    SECRET_QUESTION_LIST: `${API_BASE_URL}secret/questions`,
  }

}

export const ACTIONS = {
  LOADER: {
    SET_FP_STATUS: 'ACTIONS/LOADER/SET_FP_STATUS',
  },
  GLOBAL: {
    SET_LOGIN_MODAL: 'ACTIONS/GLOBAL/SET_LOGIN_MODAL',
    SET_FCM_TOKEN: 'ACTIONS/GLOBAL/SET_FCM_TOKEN',
    SET_DEVICE_OS: 'ACTIONS/GLOBAL/SET_DEVICE_OS',
  },
  FORGOT_PASSWORD: {
    FORGOT_PASSWORD_STEP: 'ACTIONS/FORGOT_PASSWORD/FORGOT_PASSWORD_STEP',
    FORGOT_PASSWORD_DATA: 'ACTIONS/FORGOT_PASSWORD/FORGOT_PASSWORD_DATA',
  },
  USER: {
    REGISTRATION: 'ACTIONS/USER/REGISTRATION',
    VERIFY: 'ACTIONS/USER/VERIFY',
    LOGIN: 'ACTIONS/USER/LOGIN',
    LOGIN_WITH_OTP: 'ACTIONS/USER/LOGIN_WITH_OTP',
    ME: 'ACTIONS/USER/ME',
    ONLINE_STATUS_CHANGE: 'ACTIONS/USER/ONLINE_STATUS_CHANGE',
    SOCKET_INSTANCE_CONTAINER: 'ACTIONS/USER/SOCKET_INSTANCE_CONTAINER',
    LOGOUT: 'ACTIONS/USER/LOGOUT',
    PROFILE: 'ACTIONS/USER/PROFILE',
    GUEST_USER_ID: 'ACTIONS/USER/GUEST_USER_ID',
    RESET_GUEST_USER_ID: 'ACTIONS/USER/RESET_GUEST_USER_ID',
  },
  EVENT: {
    GET_EVENT_LIST: 'ACTIONS/EVENT/GET_EVENT_LIST',
    // GET_UPCOMING_EVENT: 'ACTIONS/EVENT/GET_UPCOMING_EVENT',
    GET_EVENT_GAME: 'ACTIONS/EVENT/GET_EVENT_GAME',
    GET_EVENT_GAME_DETAILS: 'ACTIONS/EVENT/GET_EVENT_GAME_DETAILS',
    GET_STATUS_MESSAGE: 'ACTIONS/EVENT/GET_STATUS_MESSAGE',
    LEADERBOARD: 'ACTIONS/EVENT/LEADERBOARD',
    RODEO_EVENT_LOGIN_REQUIRED:  'ACTIONS/EVENT/RODEO_EVENT_LOGIN_REQUIRED',

    OPEN_WINING_MODAL: 'ACTIONS/EVENT/OPEN_WINING_MODAL',
    SET_PICK_ID: 'ACTIONS/EVENT/SET_PICK_ID',
    RESET_PICK_ID: 'ACTIONS/EVENT/RESET_PICK_ID',
    STEP1_DETAILS: 'ACTIONS/EVENT/STEP1_DETAILS',
    RESET_STEP1_DETAILS: 'ACTIONS/EVENT/RESET_STEP1_DETAILS',
    CLAIM_DETAILS: 'ACTIONS/EVENT/CLAIM_DETAILS',
    SHORT_CODE_DETAILS: 'ACTIONS/EVENT/SHORT_CODE_DETAILS',
    RESET_SHORT_CODE_DETAILS: 'ACTIONS/EVENT/RESET_SHORT_CODE_DETAILS',
    GET_COMPANY: 'ACTIONS/EVENT/GET_COMPANY',  
    COMPANY_LIST: 'ACTIONS/EVENT/COMPANY_LIST',    
    SHOW_ANNA_MODAL: 'ACTIONS/EVENT/SHOW_ANNA_MODAL',    

    //SOCKET
    GET_SOCKET_START_DATA_AND_PUSH_TO_GAME_LIST: 'ACTIONS/EVENT/GET_SOCKET_START_DATA_AND_PUSH_TO_GAME_LIST',
    GET_SOCKET_START_DATA_AND_PUSH_TO_GAME_DETAILS: 'ACTIONS/EVENT/GET_SOCKET_START_DATA_AND_PUSH_TO_GAME_DETAILS',
    GET_SOCKET_STATUS_MESSAGE_AND_PUSH_TO_STATUS_LIST: 'ACTIONS/EVENT/GET_SOCKET_STATUS_MESSAGE_AND_PUSH_TO_STATUS_LIST',
    GET_SOCKET_RIDER_WITH_VOTE_DETAILS: 'ACTIONS/EVENT/GET_SOCKET_RIDER_WITH_VOTE_DETAILS',
    GET_WINING_SOCKET_DATA_AND_PUSH_TO_WINING_DETAILS: 'ACTIONS/EVENT/GET_WINING_SOCKET_DATA',

    GET_EVENT_LIST_GLOWING_SOCKET: 'ACTIONS/EVENT/GET_EVENT_LIST_GLOWING_SOCKET',

    //Route change EVENT
    NEXT_EVENT_FROM_ROUTE: 'ACTIONS/EVENT/NEXT_EVENT_FROM_ROUTE',
    
  },
  TV_IMAGE: {
    DETAILS: 'ACTIONS/TV_IMAGE/DETAILS'
  },
  FILE: {
    GET_LISTS: 'ACTIONS/FILE/GET_LISTS',
    CREATE_LISTS: 'ACTIONS/FILE/CREATE_LISTS',
    RESET_LISTS: 'ACTIONS/FILE/RESET_LISTS'
  }
}

export const SAGA_ACTIONS = {
  USER: {
    REGISTRATION: 'SAGA_ACTIONS/USER/REGISTRATION',
    VERIFY: 'SAGA_ACTIONS/USER/VERIFY',
    LOGIN: 'SAGA_ACTIONS/USER/LOGIN',
    LOGIN_WITH_OTP: 'SAGA_ACTIONS/USER/LOGIN_WITH_OTP',
    LOGOUT: 'SAGA_ACTIONS/USER/LOGOUT',
    DETAILS: 'SAGA_ACTIONS/USER/DETAILS',
    UPDATE_PROFILE: 'SAGA_ACTIONS/USER/UPDATE_PROFILE',
    SOCIAL_LOGIN_REGISTER: 'SAGA_ACTIONS/USER/SOCIAL_LOGIN_REGISTER',
    VERIFY_WITH_OTP: 'SAGA_ACTIONS/USER/VERIFY_WITH_OTP',
  },
  EVENT: {    
    CLOWN_LANDING: 'SAGA_ACTIONS/EVENT/CLOWN_LANDING',
    ASK_QUESTION: 'SAGA_ACTIONS/EVENT/ASK_QUESTION',
    CLAIM_PAGE1: 'SAGA_ACTIONS/EVENT/CLAIM_PAGE1',
    CLAIM_PAGE2: 'SAGA_ACTIONS/EVENT/CLAIM_PAGE2',
    CLAIM_PAGE3: 'SAGA_ACTIONS/EVENT/CLAIM_PAGE3',
    MY_QUESTIONS: 'SAGA_ACTIONS/EVENT/MY_QUESTIONS',
    SUPPLIER_LIST: 'SAGA_ACTIONS/EVENT/SUPPLIER_LIST',
    MY_CLAIMS: 'SAGA_ACTIONS/EVENT/MY_CLAIMS',
    CLAIM_DETAILS: 'SAGA_ACTIONS/EVENT/CLAIM_DETAILS',
    INCOMPLETE_CLAIM: 'SAGA_ACTIONS/EVENT/INCOMPLETE_CLAIM',
    CLAIM_DELETE: 'SAGA_ACTIONS/EVENT/CLAIM_DELETE',
    CLAIM_DOCUMENTS_ZIP: 'SAGA_ACTIONS/EVENT/CLAIM_DOCUMENTS_ZIP',
    CONTACT_US: 'SAGA_ACTIONS/EVENT/CONTACT_US',
    SHORT_CODE_DETAILS: 'SAGA_ACTIONS/EVENT/SHORT_CODE_DETAILS',
    GET_COMPANY: 'SAGA_ACTIONS/EVENT/GET_COMPANY',
    COMPANY_LIST: 'SAGA_ACTIONS/EVENT/COMPANY_LIST',
    FILE_REMOVE: 'SAGA_ACTIONS/EVENT/FILE_REMOVE',
    UPLOAD_MORE_DOCUMENT: 'SAGA_ACTIONS/EVENT/UPLOAD_MORE_DOCUMENT',
    COMMENT_ADD: `SAGA_ACTIONS/EVENT/COMMENT_ADD`,
    COMMENT_REMOVE: `SAGA_ACTIONS/EVENT/COMMENT_REMOVE`,
    COMMENT_LIST: `SAGA_ACTIONS/EVENT/COMMENT_LIST`,
    MESSAGE_LIST: `SAGA_ACTIONS/EVENT/MESSAGE_LIST`,
    SEND_MESSAGE: `SAGA_ACTIONS/EVENT/SEND_MESSAGE`,
    DELETE_MESSAGE: `SAGA_ACTIONS/EVENT/DELETE_MESSAGE`,
    UPDATE_READ_STATUS: `SAGA_ACTIONS/EVENT/UPDATE_READ_STATUS`,
    DIRECTORS_LIST: `SAGA_ACTIONS/EVENT/DIRECTORS_LIST`,
  },
  TV_IMAGE: {
    DETAILS: 'SAGA_ACTIONS/TV_IMAGE/DETAILS',
  },
  CMS: {
    GET_CMS: 'SAGA_ACTIONS/COMMON/GET_CMS',
  },
  COMMON: {
    GENDER_LIST: 'SAGA_ACTIONS/COMMON/GENDER_LIST',
    COUNTRY_LIST: 'SAGA_ACTIONS/COMMON/COUNTRY_LIST',
    SECRET_QUESTION_LIST: 'SAGA_ACTIONS/COMMON/SECRET_QUESTION_LIST'
  }
}

export * from './site_statics'
export * from './site_urls'
export * from './functions'
export * from './canvasUtils'
export * from './card_utils'