import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { URLS, APPLICATION_GLOBAL_SLUG, createMarkup, stripHtml, VIDEO_URLS } from "src/_config";
import { useCmsApi } from "src/_common/hooks/actions/cms/cmsApiHook";
import { toast } from "react-toastify";
import { Controller, useForm } from 'react-hook-form';
import FormTextInput from "src/_common/components/form-elements/textinput/formTextInput";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useAppEvenListSelector, useAppRodeoEventLoginRequiredSelector, useAppShowAnnaModalSelector } from 'src/_common/hooks/selectors/eventSelector';
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import { useAppGuestUserIdSelector, useAppUserDetailsSelector } from "src/_common/hooks/selectors/userSelector";
import { useEventApi } from "src/_common/hooks/actions/event/appEventApiHook";
import { useAppUserAction } from "src/_common/hooks/actions/user/appUserActionHook";
import FormTextAreaInput from "src/_common/components/form-elements/textarea/textareaInput";
// import RecaptchaInput from 'src/_common/components/form-elements/recaptchainput/recaptchaInput';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import FormMaskedInput from "src/_common/components/form-elements/maskedInput/maskedInput";
import CheckboxInput from "src/_common/components/form-elements/checkboxinput/checkboxInput";
import { useAppEventAction } from "src/_common/hooks/actions/event/appEventActionHook";
import AskAnnaModal from "src/components/common/AskAnnaModal";


interface ContactUsFormValues {
    name: string;
    email: string;
    message: string;
    // recaptcha: string;
    // recaptchaKey: string;
    contact_name: string;
    phone: string;
    acceptTermsCndtn: boolean;
}
interface ContactUsFormProps {
    reCaptchaKey: string | null
}

const contactUsSchema = yup.object().shape({
    name: yup
        .string()
        .required('Company Name is required'),
    email: yup
        .string()
        .email('Please provide a valid email address')
        .required('Email is required'),
    message: yup
        .string()
        .required('Comment is required'),
    // recaptcha: yup
    //     .string()
    //     .nullable()
    //     .required('Please check recaptcha'),
    // .when('recaptchaKey', {
    //   is: recaptchaKey => !!recaptchaKey,
    //   then: yup.string().required('Please check recaptcha')
    // })
    contact_name: yup
        .string()
        .required('Contact Name is required'),
    // phone: yup
    //     .string()
    //     .required('Telephone is required'),
    acceptTermsCndtn: yup
        .bool()
        .oneOf([true], 'Please Accept Terms & Conditions')
})

export default function ContactUsPage() {

    const { register, control, setValue, getValues, handleSubmit, errors, watch, reset } = useForm<ContactUsFormValues>({
        resolver: yupResolver(contactUsSchema),
        defaultValues: {
            name: '',
            email: '',
            message: '',
            // recaptcha: '',
            // recaptchaKey: reCaptchaKey ? reCaptchaKey : ''
            contact_name: '',
            phone: '',
            acceptTermsCndtn: false,
        },
    })

    const cmsApi = useCmsApi();
    const [content, setContent] = useState<any>()
    const loginReq = useAppRodeoEventLoginRequiredSelector()
    const eventApi = useEventApi()
    const toast = useToaster()
    const userDetails = useAppUserDetailsSelector()
    const history = useHistory()
    const guestUserId = useAppGuestUserIdSelector()
    const userAction = useAppUserAction()
    const [token, setToken] = useState<any>();
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
    const eventAction = useAppEventAction()
    const showAskAnnaModal = useAppShowAnnaModalSelector()

    const onRegistrationFormSubmit = (values: ContactUsFormValues) => {
        console.log({ values })
        setRefreshReCaptcha(r => !r);
        if (token) {
            var parms: any = {
                name: values.name,
                email: values.email,
                message: values.message,
                captcha_token: token,
                contact_name: values.contact_name,
                phone: values.phone.replace(/\D/g, '').slice(-11),
            }
            eventApi.callContactUs(parms, (message: string, resp: any) => {
                if (resp) {
                    toast.success(message);
                    reset()
                    setToken('')
                    // window.location.reload();
                } else {
                    toast.error(message)
                }
            }, (message: string) => {
                toast.error(message)
            })
        }
    }

    const openAskAnaModal = () => {
      eventAction.showAnnaModal(true)
    }
  
    const closeAskAnnaModal = () => {
      eventAction.showAnnaModal(false)
    }

    // useEffect(() => {
    //     if (reCaptchaKey) {
    //       setValue('recaptchaKey', reCaptchaKey)
    //     }
    // }, [reCaptchaKey])

    useEffect(() => {
        // console.log(token)
    }, [token])

    useEffect(()=>{
        return () => {
            eventAction.showAnnaModal(false)
        }
    },[])

    return (
        <React.Fragment>
            <div className="contact-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="contact-box">
                                <h1>Get In Touch</h1>
                                <p>Whether you have a question about starting a new claim or want feedback about and exsisting one just give us a call.</p>
                                <ul className="contact-add-sec">
                                    <li>
                                        <span>Email</span>
                                        <p>
                                            <b>For existing claims</b><br />
                                            <a href="mailto:support@123claim.co.uk">support@123claim.co.uk</a>
                                        </p>
                                        <p>
                                            <b>For new claims</b><br />
                                            <a href="mailto:claims@123claim.co.uk">claims@123claim.co.uk</a>
                                        </p>
                                    </li>
                                    <li>
                                        <span>Call Us</span>
                                        <a href="tel:0330 122 6228">0330 122 6228</a>
                                    </li>
                                    <li className="mb-0">
                                        <span>Our Location</span>
                                        Unit 4, Clifford Court, <br />Parkhouse Estate, Carlisle, <br />Cumbria, CA3 OJG.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="contact-box">
                                <h1>Message Us</h1>

                                <form onSubmit={handleSubmit(onRegistrationFormSubmit)} noValidate>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="login-form">
                                                <label className="project-label">Company Name</label>
                                                <Controller
                                                    control={control}
                                                    name="name"
                                                    // className="form-project mb-12"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <FormTextInput
                                                            name={name}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            inputRef={ref}
                                                            type="text"
                                                            error={errors.name}
                                                        // placeholder="Your Company Name"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-6 col-12">
                                            <div className="login-form">
                                                <label className="project-label">Contact Name</label>
                                                <Controller
                                                    control={control}
                                                    name="contact_name"
                                                    // className="form-project mb-12"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <FormTextInput
                                                            name={name}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            inputRef={ref}
                                                            type="text"
                                                            error={errors.contact_name}
                                                        // placeholder="Your Contact Name"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="login-form">
                                                <label className="project-label">Email</label>
                                                <Controller
                                                    control={control}
                                                    name="email"
                                                    // className="form-project mb-12"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <FormTextInput
                                                            name={name}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            inputRef={ref}
                                                            type="text"
                                                            error={errors.email}
                                                        // placeholder="Your Company Name"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-lg-6 col-12">
                                            <label className="project-label">Telephone</label>
                                            <Controller
                                                control={control}
                                                name="phone"
                                                className="form-project mb-12"
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <FormMaskedInput
                                                        name={name}
                                                        mask="99999 999999"
                                                        maskPlaceholder="99999 999999"
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        value={value}
                                                        inputRef={ref}
                                                        type="text"
                                                        error={errors.phone}
                                                    // placeholder="Your Phone No"
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className=" col-12">
                                            <div className="login-form">
                                                <label className="project-label">Comment</label>
                                                <Controller
                                                    control={control}
                                                    name="message"
                                                    className="form-project mb-12 h-auto"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <FormTextAreaInput
                                                            name={name}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            inputRef={ref}
                                                            type="text"
                                                            error={errors.message}
                                                            // placeholder="Enter your Registered Address"
                                                            rows={5}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="login-form">

                                                <GoogleReCaptchaProvider
                                                    reCaptchaKey="6LfwycghAAAAAIbeuAwnMr3Kk6XnGBevV0hX3SEO">
                                                    <GoogleReCaptcha
                                                        onVerify={(token) => {
                                                            setToken(token);
                                                        }}
                                                        refreshReCaptcha={refreshReCaptcha}
                                                    />
                                                </GoogleReCaptchaProvider>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="d-flex align-items-start inner-cont-chk-fix cntc-pg-er">
                                                <Controller
                                                    control={control}
                                                    name="acceptTermsCndtn"
                                                    className="form-project mb-12 h-auto"
                                                    render={({ onChange, onBlur, value, name, ref }) => (
                                                        <CheckboxInput
                                                            id="acceptTermsCndtn"
                                                            name={name}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            inputRef={ref}
                                                            error={errors.acceptTermsCndtn}
                                                        // label={`I have read and agreed to the privacy policy and terms & conditions.`}
                                                        />
                                                    )}
                                                />
                                                {/* <span className="invalid-feedback">{errors?.acceptTermsCndtn}</span> */}
                                                <label className="form-check-label project-label mb-0" htmlFor="acceptTermsCndtn">
                                                    I have read and agreed to the <Link to={{ pathname: "https://123claim.co.uk/privacy-policy/" }} target="_blank">privacy policy</Link> and <Link to={{ pathname: "https://123claim.co.uk/terms-conditions/" }} target="_blank">terms & conditions</Link>.
                                                </label>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-12 text-center">
                                                <button type="submit" className="project-btn send-btn">Send Message</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                {/* </GoogleReCaptchaProvider> */}
                            </div>
                        </div>
                        <div className=" col-12">
                            <div className="contact-map">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2292.406796108184!2d-2.9566582!3d54.93088100000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487d17512ac30605%3A0x3163939a84264b2b!2sClifford%20Court%2C%20Cooper%20Way%2C%20Parkhouse%2C%20Carlisle%20CA3%200JG%2C%20UK!5e0!3m2!1sen!2sin!4v1662131306031!5m2!1sen!2sin" width="100%" height="500" loading="lazy"></iframe>

                            </div>
                        </div>
                    </div>
                </div>
                   
                {showAskAnnaModal && 
                    <AskAnnaModal
                        shouldShow={showAskAnnaModal}
                        onClose={closeAskAnnaModal}
                        url={VIDEO_URLS.REQUEST_INFO}
                        title={"Request Info"}
                    />
                }
            </div>
        </React.Fragment>
    )
}